import React, { useEffect, useRef, useState } from "react";
import MapComponent from "components/common/Maps/GoogleMap/MapComponent";
import Tooltips from "components/common/tooltipBox/Tooltip";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import "rc-slider/assets/index.css";
import FilterDrawer from "../../common/Drawer/FilterDrawer";
import {
  useGetCustomersMutation,
  useGetLeadsMutation,
} from "reduxStore/rtk/EndPoints/SageEndPoint/sageSlice";
import { initialSalesFilter } from "modules/helper";
const initialWeatherFilters = {
  date: new Date(),
  hailSize: 0,
};
const initialMapFilters = {
  user: { values: "all", label: "All" },
  property: "",
  type: "",
  structure: "",
  redeck: "",
  group: "",
  material: "",
  "start date": "",
  "end date": "",
};

const initialWeatherHistoryFilter = {
  lat: "",
  lng: "",
  miles: 0,
  range: "",
  "location Name": "",
};

const sliderStyle = {
  padding: "0 12px",
  borderRadius: "3px",
  boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3)",
  width: "250px",
};
const UserMap = () => {
  document.title = "HailStorm Map";
  const [isRight, setIsRight] = useState(false);
  const toggleRightCanvas = (isRight) => {
    setIsRight(!isRight);
  };
  const mapRef = useRef();
  const inputRef = useRef();
  const [weatherFilters, setWeatherFilters] = useState(initialWeatherFilters);
  // const [customerData, setCustomerData] = useState({ data: [] });
  const [mapFilters, setMapFilters] = useState(initialMapFilters);
  const [salesFilter, setSalesFilter] = useState(initialSalesFilter);
  const [weatherHistoryFilter, setWeatherHistoryFilter] = useState(initialWeatherHistoryFilter);
  const [showWeatherHistoryModal, setShowWeatherHistoryModal] = useState(false);
  const [invalidCoordinates, setInvalidCoordinates] = useState([]);
  const [customerFilter, setCustomerFilter] = useState(false);
  const [showSalesModal, setShowSalesModal] = useState(false);
  const [searchedLocation, setSearchedLocation] = useState(null);
  const [hailSize, setHailSize] = useState(0);
  const [isApiCalling, setIsApiCalling] = useState(false);
  const [currentPolygon, setCurrentPolygon] = useState(null);
  const [circleData, setCircleData] = useState({});
  const [polygonData, setPolygonData] = useState([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [
    getLeads,
    {
      data: leadsDatas,
      isLoading: isLeadDataLoading,
      isSuccess: leadIsSuccess,
    },
  ] = useGetLeadsMutation();
  const [
    getCustomers,
    { data: customerData, isLoading: isCustomerDataLoading, isSuccess },
  ] = useGetCustomersMutation();
  const [leadsData, setLeadData] = useState({});
  const resetFilter = () => {
    setMapFilters(initialMapFilters);
    setSalesFilter(initialSalesFilter);
    setHailSize(0);
    setWeatherFilters(initialWeatherFilters);
    currentPolygon?.setMap(null);
    setInvalidCoordinates([])
    setCurrentPolygon(null);
    // setCustomerData({ data: [] })
    setCustomerFilter(false);
    setSearchedLocation(null);
    inputRef.current.value = null;
    setIsFilterApplied(() => {
      return false;
    });
    setCircleData(null);
    setPolygonData(null);
  };

  useEffect(() => {
    if (leadsDatas && leadIsSuccess) {
      setLeadData(leadsDatas);
    } else {
      setLeadData({});
    }
  }, [leadsDatas, leadIsSuccess]);
  // const getMapFilters = (key, values) => {
  //   let msg = values?.label;
  //   if (
  //     key === "date" ||
  //     key === "hailSize" ||
  //     key === "location" ||
  //     key === "user"
  //   )
  //     return;
  //   if (values === "") {
  //     msg = "Any";
  //   } else if (Array.isArray(values)) {
  //     msg = values.map((data) => data.label).toString();
  //   }
  //   if (values === "" || values?.value === null) return;
  //   return (
  //     <Tooltips key={key + values} msg={msg} direction="bottom">
  //       <div
  //         style={{ maxWidth: "150px", cursor: "pointer" }}
  //         className={`${values === "" ? "bg-warning-subtle" : "bg-primary-subtle"}  py-1 px-3 rounded-pill fs-9 text-truncate `}
  //       >
  //         {key.toUpperCase()}:
  //         {values === ""
  //           ? "Any"
  //           : Array.isArray(values)
  //             ? values.map((data) => data.label).toString()
  //             : values.label}
  //       </div>
  //     </Tooltips>
  //   );
  // };
  const getSalesFilter = (key, values) => {
    let msg = values;
    if (key === "lat" || key === "lng") return;
    if (values === "") {
      return;
    } else if (Array.isArray(values)) {
      msg = values.toString();
    }
    if (values === "") return;
    return (
      <Tooltips key={key + values} msg={msg} direction="bottom">
        <div
          style={{ maxWidth: "150px", cursor: "pointer" }}
          className={`${values === "" ? "bg-warning-subtle" : "bg-primary-subtle"}  py-1 px-3 rounded-pill fs-9 text-truncate `}
        >
          {key.toUpperCase()}:
          {Array.isArray(values) ? values.toString() : values}
        </div>
      </Tooltips>
    );
  };

  const handleSalesFilter = () => {
    setShowSalesModal(true);
  };
  const handleWeatherHistoryModal = () => {
    setShowWeatherHistoryModal(true);
  };


  const handleHailSize = (hailSize) => {
    setWeatherFilters({ ...weatherFilters, hailSize });
  };
  return (
    <React.Fragment>
      <div
        id="gmaps-markers"
        className="gmaps"
        style={{ position: "relative" }}
      >
        {/* <DrawingMap /> */}
        <MapComponent
          inputRef={inputRef}
          mapRef={mapRef}
          searchedLocation={searchedLocation}
          setSearchedLocation={setSearchedLocation}
          mapFilters={mapFilters}
          isHeader={true}
          setMapFilters={setMapFilters}
          weatherFilters={weatherFilters}
          setIsApiCalling={setIsApiCalling}
          circleData={circleData}
          setCircleData={setCircleData}
          polygonData={polygonData}
          invalidCoordinates={invalidCoordinates}
          setInvalidCoordinates={setInvalidCoordinates}
          setPolygonData={setPolygonData}
          hailSize={hailSize}
          setHailSize={setHailSize}
          isFilterApplied={isFilterApplied}
          setIsFilterApplied={setIsFilterApplied}
          currentPolygon={currentPolygon}
          setCurrentPolygon={setCurrentPolygon}
          resetFilter={resetFilter}
          headFunc={{
            // getMapFilters,
            getSalesFilter,
            handleSalesFilter,
            handleHailSize,
            salesFilter,
            setSalesFilter,
            customerFilter,
            getCustomers,
            showWeatherHistoryModal,
            weatherHistoryFilter,
            setWeatherHistoryFilter,
            setShowWeatherHistoryModal,
            handleWeatherHistoryModal,
            customerData,
            isCustomerDataLoading,
            isSuccess,
            setCustomerFilter,
            showSalesModal,
            getLeads,
            setShowSalesModal,
            isApiCalling,
            setIsApiCalling,
            leadIsSuccess,
            isLeadDataLoading,
            leadsData,
            toggleRightCanvas,
            sliderStyle,
            resetFilter,
            isRight,
            setLeadData,
            setIsRight,
            initialWeatherFilters,
            setWeatherFilters,
            initialMapFilters,
            initialSalesFilter,
          }}
        // showUserCanvas={showUserCanvas}
        // toggleUserCanvas={toggleUserCanvas}
        />
      </div>
      {isRight && (
        <FilterDrawer
          isRight={isRight}
          setCustomerFilter={setCustomerFilter}
          setIsRight={setIsRight}
          toggleRightCanvas={toggleRightCanvas}
          setCurrentPolygon={setCurrentPolygon}
          invalidCoordinates={invalidCoordinates}
          setMapFilters={setMapFilters}
          getLeads={getLeads}
          mapFilters={mapFilters}
          getCustomers={getCustomers}
        />
      )}
    </React.Fragment>
  );
};

export default UserMap;
