import clientLogo from "assets/images/clientLogo.png";
import PageTitle from "components/common/PageTitle";
import TextField from "components/common/fields/TextField";
import { ErrorMessage, Form, Formik } from "formik";
import { Card, Container } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import AuthCarousel from "./AuthCarousel";
import { useForgotPasswordMutation } from "reduxStore/rtk/EndPoints/LoginEndPoint/LoginSlice";
import { showSuccessMsg } from "modules/helper";
import ProcessButton from "components/common/fields/ProcessButton";

const PasswordReset = () => {
  const navigate = useNavigate();
  const [forgotPassword, { isLoading }] = useForgotPasswordMutation();
  const handleSubmit = async (values) => {
    let res = await forgotPassword(values);
    if (res?.data?.type === "success") {
      showSuccessMsg(res?.data?.message);
      navigate("/login");
    }
  };

  return (
    <>
      <PageTitle title="Reset Password" />
      <Container>
        <div className="d-flex flex-column justify-content-center">
        <div className="text-center my-5">
            <span className="logo-lg ">
              <img src={clientLogo} alt="" height="95" />
            </span>
          </div>
          <div className="my-5 d-flex flex-column flex-lg-row justify-content-center gap-2 gap-lg-0">
            <Card className="w-100 w-lg-50 my-auto overflow-hidden">
              <Card.Body className="p-lg-5 p-4">
                <div className="text-center">
                  <h5 className="mb-0">Forgot Password?</h5>
                </div>

                <div className="text-center my-5">
                  <div
                    className="alert alert-borderless alert-warning text-center mb-2 mx-2"
                    role="alert"
                  >
                    Enter your email and instructions will be sent to you!
                  </div>
                </div>

                <div className="mt-4">
                  <Formik
                    initialValues={{
                      email: "",
                    }}
                    validationSchema={yup.object({
                      email: yup.string().email("Please Enter Valid Email").required("Please Enter Email"),
                    })}
                    onSubmit={handleSubmit}
                  >
                    {({
                      errors,
                      touched,
                      values,
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      setFieldValue,
                      setFieldTouched,
                    }) => (
                      <Form
                        id="form1"
                        name="form1"
                        action="#"
                        className="mt-4"
                        onSubmit={handleSubmit}
                        onKeyDown={(e) => {
                          e.key === "Enter" && e.preventDefault();
                        }}
                      >
                        <TextField
                          label="Email"
                          name="email"
                          value={values?.email}
                          placeholder="Enter Email"
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          validationMsg={<ErrorMessage name="email" />}
                        />
                        <div className="mt-2">
                          <ProcessButton
                            label={"Send Reset Link"}
                            type={"submit"}
                            isLoading={isLoading}
                          />
                        </div>
                        <div className="mt-4 text-center">
                          <p className="mb-0">
                            Remember password?{" "}
                            <Link
                              to="/login"
                              className="fw-medium text-primary text-decoration-underline"
                            >
                              {" "}
                              Login{" "}
                            </Link>{" "}
                          </p>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </Card.Body>
            </Card>
            <Card className="w-100 w-lg-50 mb-0">
              <AuthCarousel />
            </Card>
          </div>
        </div>
      </Container>
    </>
  );
};

export default PasswordReset;
