import React, { useMemo } from "react";
import TableContainer from "components/common/Tabledata/TableContainer";
import { Card } from "react-bootstrap";

const NearbySalesPersonInfoWindow = ({ salesData, tableHeight = "250px" }) => {
  // const [getNearbySalesPerson, { isLoading: isNearbySalesPersonDataLoading }] =
  //   useGetNearbySalesmanMutation();

  const columns = useMemo(
    () => [
      {
        Header: "JOB NUMBER",
        accessor: "customerId",
        Filter: false,
        isSortable: false,
      },
      {
        Header: "DATE",
        accessor: "customerDate",
        Filter: false,
        isSortable: false,
      },
      {
        Header: "LEAD NO",
        accessor: "leadNo",
        Filter: false,
        isSortable: false,
      },
      {
        Header: "NAME",
        accessor: "name",
        Filter: false,
        isSortable: false,
        Cell: (cell) => {
          return (
            <div
              className=""
              style={{
                width: "130px",
                wordBreak: "break-word", 
                overflowWrap: "break-word",
                wordWrap: "break-word", 
                whiteSpace: "normal", 
              }}
            >
              {cell.row.original.name}
            </div>
          );
        },
      },
      {
        Header: "ADDRESS",
        accessor: "address",
        Filter: false,
        isSortable: false,
        Cell: (cell) => {
          return (
            <div
              className=""
              style={{
                width: "150px",
                wordBreak: "break-word", 
                overflowWrap: "break-word",
                wordWrap: "break-word", 
                whiteSpace: "normal", 
              }}
            >
              {cell.row.original.address}
            </div>
          );
        },
      },
      {
        Header: "DISTANCE",
        accessor: "distance",
        Filter: false,
        isSortable: false,
      },
      {
        Header: "EMPLOYEE",
        accessor: "employee",
        Filter: false,
        isSortable: false,
      },
      {
        Header: "MATERIAL",
        accessor: "material",
        Filter: false,
        isSortable: false,
      },
    ],
    []
  );

  return (
    <>
      <Card className="m-0 p-0">
        <Card.Body>
          {/* show sales data in table */}
          <TableContainer
            columns={columns}
            data={salesData || []}
            customPageSize={9999999}
            divClassName="table-card table-responsive text-start  overflow-hidden"
            tableClass="table-hover table-nowrap align-middle mb-0 border table"
            isBordered={false}
            PaginationClass="align-items-center"
            tableHeight={tableHeight}
          />
        </Card.Body>
      </Card>
    </>
  );
};

export default NearbySalesPersonInfoWindow;
