import { removeAuthToken, removeRefreshToken, setAuthToken, removeAuthSession } from "modules/util";
import { api } from "reduxStore/Service";

// authenticaton api and package api
const loginSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (payload) => ({
        method: "POST",
        url: "/auth/login",
        body: payload,
        headers: {
          "Content-Type": "application/json",
        },
      }),
      transformResponse: (response) => {
        if (response?.type === "success") {
          setAuthToken(response?.data?.tokens?.refresh?.token);
        }
        return response;
      },
      invalidatesTags: ["UserProfile"]
    }),
    logout: builder.mutation({
      query: (payload) => ({
        method: "POST",
        url: "/auth/logout",
        body: { refreshToken: payload?.refreshToken },
        headers: {
          "Content-Type": "application/json",
          "authorization": `Bearer ${payload?.token}`
        },
      }),
      transformResponse: (response) => {
        if (response?.type === "success") {
          removeAuthToken("TOKEN");
          removeRefreshToken("TOKEN");
          removeAuthSession("Session");
        }
        return response;
      },
    }),
    forgotPassword: builder.mutation({
      query: (payload) => ({
        method: "POST",
        url: "/auth/forgot-password",
        body: payload,
        headers: {
          "Content-Type": "application/json",
        },
      }),
    }),
    resetPassword: builder.mutation({
      query: (payload) => ({
        method: "POST",
        url: `/auth/reset-password?token=${payload?.token}`,
        body: { password: payload?.password },
        headers: {
          "Content-Type": "application/json",
        },
      }),
    }),
    refresh: builder.mutation({
      query: refreshToken => ({
        url: "/auth/refresh-tokens",
        method: "POST",
        body: { ...refreshToken },
      }),
      extraOptions: {
        customRefresh: true,
      },
    }),
  }),
});
export const { useLoginMutation, useLogoutMutation, useForgotPasswordMutation, useResetPasswordMutation, useRefreshMutation } = loginSlice;