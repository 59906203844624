import { configureStore } from "@reduxjs/toolkit";
import { api, weatherApi, sage } from "reduxStore/Service";
import { setupListeners } from "@reduxjs/toolkit/query";
import rootReducer from "reduxStore/slices/index";
import authSlice from "./rtk/EndPoints/LoginEndPoint/authSlice";

const middlewareReducer = (getDefaultMiddleware) =>
  getDefaultMiddleware().concat(api.middleware).concat(weatherApi.middleware).concat(sage.middleware)

export const store = configureStore({
  reducer: {
    rootReducer: rootReducer, // This is use for normal react redux
    [api.reducerPath]: api.reducer, // This is use for RTK
    [weatherApi.reducerPath]: weatherApi.reducer, // This is for Weather api
    [sage.reducerPath]: sage.reducer, // This is for sage microservices.
    auth: authSlice,
  },
  middleware: (getDefaultMiddleware) => middlewareReducer(getDefaultMiddleware), // Correctly applying middleware setup function
  devTools: true, // Ensure devTools is placed correctly as a separate key
});

setupListeners(store.dispatch);
