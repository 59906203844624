import PageTitle from "components/common/PageTitle";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { UserList } from "./userList";
import { useState, useEffect } from "react";
import UserModal from "components/common/UserModal";
import { useSyncSalesPersonMutation } from "reduxStore/rtk/EndPoints/SageEndPoint/sageSlice";
import { showSuccessMsg } from "modules/helper";
import { useLazyGetUserQuery } from "reduxStore/rtk/EndPoints/UserEndPoint/UserSlice";
import { Spinner } from "react-bootstrap/esm";

const initialUserFilters = {
  url: "/users",
  name: "",
  sortBy: {
    name: "",
    email: "",
  },
  limit: 10,
  page: 1,
  email: "",
  isActive: "",
};
const Users = () => {
  /* userFilter State
   * Keys in userFilter are used to set header parameter while calling api
   * while calling the /users endpoint, we pass the filter in payload, and according to the filter header params are decided
   */
  const [userFilter, setUserFilter] = useState(initialUserFilters);
  const [addUserModal, setAddUserModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState(userFilter.name);
  const handleAddUser = () => {
    setAddUserModal(!addUserModal);
  };

  const [syncSalesPerson, { data, isLoading: syncSalesPersonDataLoading }] =
    useSyncSalesPersonMutation();
  const [getUsers, { isLoading: getUsersLoading }] = useLazyGetUserQuery();

  const onChangeInSelect = (event, totalPages) => {
    if (event?.target?.value) {
      setUserFilter({ ...userFilter, limit: event?.target?.value, page: 1 });
    }
  };

  const handleSyncSalesPerson = async () => {
    let res = await syncSalesPerson({
      url: "sage/sync-sales-person",
      data: "",
    });
    if (res?.data?.type === "success") {
      showSuccessMsg(res?.data?.message);
      getUsers(initialUserFilters);
      setUserFilter(initialUserFilters);
    }
  };
  const isApiCalling = getUsersLoading || syncSalesPersonDataLoading;

  // Debounce the search term
  useEffect(() => {
    const timer = setTimeout(() => {
      setUserFilter({ ...userFilter, name: searchTerm, page: 1 });
      getUsers({ ...userFilter, name: searchTerm, page: 1 }); // Set the debounced search term
    }, 500); // 1 second debounce

    return () => clearTimeout(timer); // Clean up on input change
  }, [searchTerm]);

  return (
    <>
      <PageTitle title="Users" />
      <Card>
        <Card.Header>
          <Row>
            <Col xl={6}>
              <Button
                onClick={handleAddUser}
                disabled={isApiCalling}
                className="me-2"
              >
                Add User <i className="las la-plus"></i>
              </Button>
              <Button disabled={isApiCalling} onClick={handleSyncSalesPerson}>
                Sync Sales{" "}
                {syncSalesPersonDataLoading ? (
                  <Spinner size="sm" />
                ) : (
                  <i className="bi bi-arrow-repeat"></i>
                )}
              </Button>
            </Col>

            <Col xl={3}>
              <div className="search-box">
                <Form.Control
                  type="text"
                  name="name"
                  placeholder="Search by name or email"
                  disabled={isApiCalling}
                  value={searchTerm}
                  onChange={(e) => {
                    setSearchTerm(e.target.value); // Set the search term
                  }}
                />
                <i className="las la-search search-icon"></i>
              </div>
            </Col>
            <Col xl={2}>
              <Form.Select
                aria-label=".form-select-sm example"
                disabled={isApiCalling}
                onChange={(e) => {
                  setUserFilter({
                    ...userFilter,
                    isActive: e.target.value,
                    page: 1,
                  });
                }}
              >
                <option selected={userFilter.isActive === ""} value="">
                  All
                </option>
                <option value={true}>Active</option>
                <option value={false}>In Active</option>
              </Form.Select>
            </Col>
            <Col xl={1} className="px-0">
              <div className="">
                <select
                  className="form-select"
                  value={userFilter?.limit}
                  onChange={onChangeInSelect}
                  disabled={isApiCalling}
                >
                  {[10, 20, 30, 40, 50].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      Show {pageSize}
                    </option>
                  ))}
                </select>
              </div>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="overflow-auto">
          <UserList
            userFilter={userFilter}
            setUserFilter={setUserFilter}
            isApiCalling={isApiCalling}
          />
        </Card.Body>
      </Card>
      <UserModal
        modalTitle="Add User"
        isShow={addUserModal}
        handleClose={() => setAddUserModal(false)}
      />
    </>
  );
};

export default Users;
