import { useState } from "react";

export default function PasswordField({
  label = "",
  name = "",
  value = "",
  placeholder = "",
  validationMsg = "",
  handleBlur = () => {},
  handleChange = () => {},
  isDisabled = false,
  isReadOnly = false,
  icon = "",
}) {
  const [showPassword, setShowPassword] = useState(false);
  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="w-100 mt-3">
      <label htmlFor={name}>{label}</label>
      <div className="position-relative w-full">
        {!!icon && (
          <button className="btn position-absolute top-0 start-0 cursor-none">
            <i className={`${icon}`}></i>
          </button>
        )}
        <input
          id={name}
          name={name}
          type={showPassword ? "text" : "password"}
          value={value}
          onBlur={handleBlur}
          onChange={handleChange}
          className={`form-control rounded-0 fs-14 pe-5`}
          placeholder={placeholder}
          disabled={isDisabled}
          readOnly={isReadOnly}
          style={{ paddingLeft: icon && "35px" }}
        />
        <button
          className="btn position-absolute top-0 end-0"
          type="button"
          onClick={() => togglePassword()}
          id="password-addon"
        >
          <i
            className={`${!showPassword ? `ri-eye-line` : `ri-eye-off-line`} `}
          ></i>
        </button>
      </div>
      <div className="text-danger">{validationMsg}</div>
    </div>
  );
}
