import { Autocomplete } from "@react-google-maps/api";

import { autocompleteStyle, showWarningMsg } from "modules/helper";
import React, { useRef } from "react";

const SearchLocation = ({
  setSearchedLocation,
  mapRef,
  inputRef,
  isDisabled,
  setMarker
}) => {
  const autocompleteRef = useRef();

  const onLoadAutocomplete = (autocomplete) => {
    autocompleteRef.current = autocomplete;
  };
  const onPlaceChanged = async () => {
    const place = autocompleteRef.current.getPlace();
    const bounds = new window.google.maps.LatLngBounds();
    if (place.name === "") {
      setSearchedLocation(null);
      return;
    }
    if (!place.geometry) {
      showWarningMsg("Please Select Valid Location");
      inputRef.current.value = null;      return;
      // try {
      //   //&components=country:US|administrative_area:TX restrict search in texas
      //   let res = await axios.get(
      //     `https://maps.googleapis.com/maps/api/geocode/json?address=${place.name}&&components=country:US&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
      //   );
      //   let location = res?.data?.results[0]?.geometry;
      //   if (location?.viewport) {
      //     bounds.extend(location.viewport.northeast);
      //     bounds.extend(location.viewport.southwest);
      //     console.log(location, "location");
      //     console.log(res?.data?.results[0]);
      //     setSearchedLocation((prev) => ({
      //       lat: location?.location?.lat,
      //       location: res?.data?.results[0]?.address_components[0]?.long_name ??"",
      //       lng: location?.location?.lng,
      //       icon: location?.icon || "",
      //       isLocation: true,
      //     }));
      //     mapRef.current.fitBounds(bounds);
      //   } else {
      //     showWarningMsg("Please Select Valid Location");
      //   }
      // } catch (error) {
      //   console.log(error);
      // }
      // return;
    }
    if (place.geometry?.viewport) {
      bounds.union(place.geometry?.viewport);
    } else {
      bounds.extend(place.geometry?.location);
    }
    setSearchedLocation((prev) => ({
      lat: place?.geometry?.location?.lat(),
      location: place?.formatted_address,
      lng: place?.geometry?.location?.lng(),
      icon: place?.icon,
      isLocation: true,
    }));
    mapRef.current.fitBounds(bounds);
  };
  return (
    <Autocomplete
      onLoad={onLoadAutocomplete}
      onPlaceChanged={onPlaceChanged}
      restrictions={{
        country: "us",
      }}
      options={{
        fields: ["formatted_address", "geometry", "name", "icon"],
        strictBounds: true,
      }}
    >
      <input
        ref={inputRef}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          }
        }}
        onChange={(e) => {
          if (e.target.value === "") {
            setSearchedLocation(null);
            setMarker(null);
          }
        }}
        id="location-box"
        // className="full-screen-search"
        type="search"
        placeholder="Search Location"
        style={autocompleteStyle}
        disabled={isDisabled}
      />
    </Autocomplete>
  );
};

export default SearchLocation;
