import Tooltips from "components/common/tooltipBox/Tooltip";
import { Dropdown } from "react-bootstrap";
import Cookies from "universal-cookie";

const setAuthToken = (token) => {
  return localStorage.setItem("AUTH_TOKEN", token);
};
const getAuthToken = () => {
  return localStorage.getItem("AUTH_TOKEN") ?? "";
};
const removeAuthToken = () => {
  localStorage.removeItem("USER_ROLE");
  return localStorage.removeItem("AUTH_TOKEN");
};

const setRefreshToken = (token) => {
  return localStorage.setItem("REFRESH_TOKEN", token);
};
const getRefreshToken = () => {
  return localStorage.getItem("REFRESH_TOKEN") ?? "";
};
const removeRefreshToken = () => {
  return localStorage.removeItem("REFRESH_TOKEN");
};


const setAuthSession = (expiry) => {
  return localStorage.setItem("AUTH_SESSION", expiry);
};
const getAuthSession = () => {
  return localStorage.getItem("AUTH_SESSION") ?? "";
};
const removeAuthSession = () => {
  return localStorage.removeItem("AUTH_SESSION");
};

const getRole = () => { };

const cookies = new Cookies();

const getCookieToken = () => {
  return cookies.get(process.env.REACT_APP_COOKIE_NAME);
};

const setCookieToken = (token) => {
  cookies.set(process.env.REACT_APP_COOKIE_NAME, token, {
    path: process.env.REACT_APP_COOKIE_PATH,
    domain: process.env.REACT_APP_COOKIE_DOMAIN,
  });
};

const removeCookie = () => {
  cookies.remove(process.env.REACT_APP_COOKIE_NAME, {
    path: process.env.REACT_APP_COOKIE_PATH,
    domain: process.env.REACT_APP_COOKIE_DOMAIN,
  });
  window.location.reload();
};

const getActionButtonsDropdown = (
  label = "",
  tooltipText = "",
  onClickAction = () => { },
  iconName = "",
  buttonColor = "muted"
) => {
  return <Tooltips msg={tooltipText} direction={"left"}>
    <Dropdown.Item
    style={{zIndex:100}}
      onClick={() => {
        onClickAction();
      }}
    >
      <i className={`${iconName}  fs-18 align-middle me-2 text-${buttonColor}`}></i>
      {label}
    </Dropdown.Item>
  </Tooltips>
}
const getMapTheme = () => {
  return JSON.parse(localStorage.getItem("MAP_THEME")) ?? { name: "", theme: "" };
};
export {
  setAuthToken,
  getAuthToken,
  removeAuthToken,
  getRole,
  getCookieToken,
  setCookieToken,
  removeCookie,
  setRefreshToken,
  getRefreshToken,
  removeRefreshToken,
  setAuthSession,
  getAuthSession,
  removeAuthSession,
  getActionButtonsDropdown,
  getMapTheme
};
