import React, { useRef, useState } from "react";
import dummy from "../../assets/images/users/user-dummy-img.jpg";
import { Button, Form, Image, Modal, Spinner } from "react-bootstrap";
import { MAX_IMAGE_SIZE, showSuccessMsg } from "modules/helper";
import {
  useUploadProfileMutation,
  useUserProfileQuery,
} from "reduxStore/rtk/EndPoints/LoginEndPoint/userProfileSlice";
import ImageCroperModel from "./ImageCroperModel"; // used to handle image crop
import { useFormik } from "formik";
import { updateAdminProfileValidation } from "modules/validation";

const ProfileModal = ({ isShow, handleClose }) => {
  const { data, isSuccess } = useUserProfileQuery(); // getting user profile
  const [updateUser, { isLoading }] = useUploadProfileMutation(); // for updating user profile
  const [nameChangedFlag, setNameChangedFlag] = useState(false); // used to indicate change in name
  const [modalShow, setModalShow] = useState(false);
  const [file, setFile] = useState([]);
  const inputFile = useRef(null);
  // Closes the crop modal and handles file upload
  const handleImageCrop = (file) => {
    // Check if the first file's size exceeds MAX_IMAGE_SIZE
    if (file[0]?.size >= MAX_IMAGE_SIZE * 1024 * 1024) {
      // Display a toast message for large file size
      showSuccessMsg(
        `The image should not exceed ${MAX_IMAGE_SIZE} MB in size.`
      );
      return; // Exit the function if the file size is too large
    }

    // Check if file exists and has elements
    if (file?.length > 0) {
      // Set the file state and display the modal for cropping
      setFile([...file]);
      setModalShow(true);
    }
  };
  const handleCloseCropModal = (Ufile = file) => {
    // Close the modal
    setModalShow(false);
    // Check if Ufile is an array and has elements
    if (!file?.length || file.length === 0) {
      setFile([]);
      return; // No file or empty array, exit early
    }
  };

  const formik = useFormik({
    initialValues: {
      name: data?.data?.name,
    },
    validationSchema: updateAdminProfileValidation(),
    onSubmit: (values) => handleSubmit(values),
    enableReinitialize: true,
  });

  const handleSubmit = async (values) => {
    try {
      const data = new FormData();
      data.append("name", values.name);
      let res = await updateUser(data);
      if (res?.data?.type === "success") {
        showSuccessMsg(res?.data?.message);
        handleClose();
        setNameChangedFlag(false);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <React.Fragment>
      <Modal
        centered
        show={isShow}
        onHide={handleClose}
        style={{ display: "block" }}
        tabIndex={-1}
      >
        <div className="modal-content border border-0">
          <Modal.Header className="p-4 pb-0">
            <Modal.Title as="h5">Profile</Modal.Title>
            <button
              type="button"
              className="btn-close"
              onClick={handleClose}
            ></button>
          </Modal.Header>
          <Modal.Body className="p-4">
            <div className="text-center">
              <div className="position-relative d-inline-block">
                <div className="avatar-lg p-1">
                  <div className="avatar-title bg-light rounded-circle">
                    <Image
                      src={
                        isSuccess && data?.data?.profileImage
                          ? `${process.env.REACT_APP_IMAGE_BASE_URL}profile/${data?.data?.profileImage}`
                          : dummy
                      }
                      alt=""
                      id="product-img"
                      className="avatar-md rounded-circle object-cover"
                      // setting fallback image if src image doesn't load
                      onError={(event) => {
                        event.currentTarget.src = dummy;
                        event.currentTarget.onerror = null;
                      }}
                    />
                    <Form.Label
                      htmlFor="product-image-input"
                      className="mb-0 position-absolute bottom-0 end-0"
                      data-bs-toggle="tooltip"
                      data-bs-placement="right"
                      title="Change Image"
                    >
                      <div className="avatar-xs cursor-pointer">
                        <div className="avatar-title bg-light border rounded-circle text-muted">
                          <i className="ri-image-fill"></i>
                        </div>
                      </div>
                    </Form.Label>
                    <Form.Control
                      name="profileImage"
                      className="form-control d-none"
                      onClick={() => inputFile.current.click()}
                      value=""
                      ref={inputFile}
                      id="product-image-input"
                      type="file"
                      accept="image/png, image/gif, image/jpeg"
                      onChange={(e) => {
                        handleImageCrop(e.target.files);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <Form
              id="form1"
              name="form1"
              action="#"
              className="mt-4"
              onSubmit={formik.handleSubmit}
              onKeyDown={(e) => {
                e.key === "Enter" && e.preventDefault();
              }}
            >
              <div className="mb-3">
                <Form.Label htmlFor="Name-input">Name</Form.Label>
                <Form.Control
                  type="text"
                  id="Name-input"
                  name="name"
                  placeholder="Enter Your name"
                  value={formik.values?.name}
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    formik.handleChange(e);
                    setNameChangedFlag(true);
                  }}
                  isInvalid={!!formik.errors.name && !!formik.touched.name}
                />
                {formik.errors.name && formik.touched.name ? (
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {" "}
                    {formik.errors.name}{" "}
                  </Form.Control.Feedback>
                ) : null}
              </div>
              <div className="mb-3 bg-white-50">
                <Form.Label htmlFor="Email-input">Email</Form.Label>
                <Form.Control
                  className="text-muted bg-light"
                  type="text"
                  id="Email-input"
                  style={{
                    cursor: "not-allowed"
                  }}
                  name="email"
                  placeholder="Enter Your email"
                  value={data?.data?.email}
                  disabled
                  readOnly
                />
              </div>
              {/* <Button type="submit">Update</Button> */}
              <div className="hstack gap-2 justify-content-end">
                <Button
                  type="submit"
                  className="btn btn-success"
                  disabled={isLoading || !nameChangedFlag}
                >
                  {isLoading && <Spinner size="sm" />}
                  Update
                </Button>
              </div>
            </Form>

            {/* <div className="mt-5 text-center">
              <p className="mb-0 text-muted">
                &copy; {new Date().getFullYear()} HailStorm.
              </p>
            </div> */}
            {modalShow && (
              <ImageCroperModel
                imageArry={file}
                onCancel={handleCloseCropModal}
                setFile={setFile}
                aspect={3 / 2}
                modalShow={modalShow}
                setModalShow={setModalShow}
              />
            )}
          </Modal.Body>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default ProfileModal;
