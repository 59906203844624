import { Carousel, Col } from "react-bootstrap";

const AuthCarousel = () => {
  return (
    <Col lg={12} className="h-100">
      <div className="d-flex h-100 bg-auth align-items-end ">
        <div className="p-lg-5 p-4 ">
          <div className="bg-overlay bg-primary"></div>
          <div className="p-0 p-sm-4 px-xl-0 py-5">
            <Carousel indicators={false} controls={false}>
              <Carousel.Item>
                <div className="testi-contain text-center">
                  <h5 className="fs-20 text-white mb-0">
                    “Stay informed, Act swiftly”
                  </h5>
                  <p className="fs-15 text-white-50 mt-2 mb-0">
                    Our application deliver the hailstorm data directly to your system and export their details into Excel for proactive outreach.
                    stay ahead of severe weather conditions by automatically updating and ensuring you have the latest information at your fingertips.
                  </p>
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
        </div>
      </div>
    </Col>
  );
};

export default AuthCarousel;
