import { weatherApi } from "reduxStore/Service";
import { authConfig } from "reduxStore/rtk/Query/Config";

export const weatherData = weatherApi.injectEndpoints({
  tagTypes: ["weather"],
  endpoints: (builder) => ({
    getWeatherData: builder.mutation({
      query: (payload) => {
        return authConfig("POST", `${payload?.url}`, payload?.data);
      },
      transformResponse: (response) => {
        return response;
      },
      invalidatesTags: ["weather"],
    }),
  }),
});

export const { useGetWeatherDataMutation } = weatherData;
