export default function CheckboxField({
  label = "",
  name = "",
  value = "",
  isDisabled = false,
  className = "",
  handleChange = () => {},
  validationMsg = "",
}) {
  return (
    <div className="w-100 mt-3">
      <div className={`d-flex gap-2`}>
        <input
          id={name}
          name={name}
          type={"checkbox"}
          value={value}
          checked={value}
          className="form-check-input"
          disabled={isDisabled}
          onChange={handleChange}
        />
        <label className={``} htmlFor={name}>
          {label}
        </label>
      </div>
      {!!validationMsg && <div className="text-danger">{validationMsg}</div>}
    </div>
  );
}
