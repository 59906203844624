import { createSlice } from "@reduxjs/toolkit"
const authSlice = createSlice({
    name: "auth",
    initialState: {
        user: null,
        token: null,
        logout: false,
        selfLogout: false,
        serverError: false,
    },
    reducers: {
        setCredentials: (state, action) => {
            const { user, tokens, token } = action.payload;
            state.user = user?.name
            state.token = token || tokens.access.token
            state.logout = false
            state.selfLogout = false
            return
        },
        setUserProfile: (state, action) => {
            state.user = action.payload
            state.logout = false
            return
        },
        logOut: (state, action) => {
            state.user = null
            state.token = null
            state.logout = true
            state.selfLogout = action.payload?.selfLogout || false
            return
        },
        serverError: (state, action) => {
            state.serverError = action.payload
            return
        },
    },
})

export const { setCredentials, logOut, setUserProfile, serverError } =
    authSlice.actions

export default authSlice.reducer

export const selectCurrentUser = state => state.auth.user
export const selectCurrentToken = state => state.auth.token
export const selectLogout = state => state.auth.logout
export const selectSelfLogout = state => state.auth.selfLogout
export const selectServerError = state => state.auth.serverError
export const selectPermissions = state => state.auth.permissions
