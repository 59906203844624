const Navdata = () => {
  const menuItems = [
    {
      id: "dashboard",
      label: "Dashboard",
      icon: "las la-house-damage",
      link: "/dashboard",
      role: ["Super Admin", "Admin", "User"],
      click: function () { },
    },
    {
      id: "user",
      label: "Users",
      link: "/users",
      icon: "ri-user-line",
      role: ["Super Admin", "Admin"],
      click: function (e) {
        e.preventDefault();
      },
    },
    {
      id: "notification",
      label: "Notification",
      link: "/notification",
      icon: "las la-bell",
      role: ["Super Admin", "Admin", "User"],
      click: function (e) {
        e.preventDefault();
      },
    },
    {
      id: "reports",
      label: "Reports",
      link: "/reports",
      icon: "ri-file-download-line",
      role: ["Super Admin", "Admin", "User"],
      click: function (e) {
        e.preventDefault();
      },
    },
  ];
  return { menuItems };
};

export default Navdata;
