import { getAuthToken } from "modules/util";
import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

const NoAuthLayout = ({ children }) => {
  const navigate = useNavigate();

  const isLoggedIn = !!getAuthToken();

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/dashboard");
    }
    // eslint-disable-next-line
  }, [navigate]);

  return (
    <>
      {children}
      <Outlet />
    </>
  );
};

export default NoAuthLayout;
