import { AuthContext } from "components/context/UserContext";
import React, { useContext } from "react";

const FullScreenDropdown = () => {
  const { isFullScreenMode, setIsFullScreenMode } = useContext(AuthContext);
  /*
    mode
    */

  /*
    full screen
    */
  // const { pathname } = useLocation();
  const toggleFullscreen = () => {
    let document = window.document;
    document.body.classList.add("fullscreen-enable");

    if (
      !document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      setIsFullScreenMode(false);
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen();
      }
    } else {
      setIsFullScreenMode(true);
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
    // handle fullscreen exit
    const exitHandler = () => {
      if (
        !document.webkitIsFullScreen &&
        !document.mozFullScreen &&
        !document.msFullscreenElement
      ) {
        setIsFullScreenMode(true);
        document.body.classList.remove("fullscreen-enable");
      }
    };
    document.addEventListener("fullscreenchange", exitHandler);
    document.addEventListener("webkitfullscreenchange", exitHandler);
    document.addEventListener("mozfullscreenchange", exitHandler);
  };
  return (
    <React.Fragment>
      <div className="ms-1 header-item d-none d-sm-flex">
        <button
          onClick={toggleFullscreen}
          type="button"
          className="btn btn-icon btn-topbar btn-ghost-primary rounded-circle"
        >
          <i
            className={
              isFullScreenMode ? "las la-expand fs-24" : "las la-compress fs-24"
            }
          ></i>
        </button>
      </div>
    </React.Fragment>
  );
};
export default FullScreenDropdown;
