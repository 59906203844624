import clientLogo from "assets/images/clientLogo.png";
import PageTitle from "components/common/PageTitle";
import CheckboxField from "components/common/fields/CheckboxField";
import PasswordField from "components/common/fields/PasswordField";
import ProcessButton from "components/common/fields/ProcessButton";
import TextField from "components/common/fields/TextField";
import withRouter from "components/common/withRouter";
import { ErrorMessage, Form, Formik } from "formik";
import { loginFormValidation } from "modules/validation";
import { useContext } from "react";
import { Card, Container } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useLoginMutation } from "reduxStore/rtk/EndPoints/LoginEndPoint/LoginSlice";
import AuthCarousel from "./AuthCarousel";
import { AuthContext } from "components/context/UserContext";
import { useDispatch } from "react-redux";
import { setCredentials } from "reduxStore/rtk/EndPoints/LoginEndPoint/authSlice";
import { decryption, encryption, showErrorMsg } from "modules/helper";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const { setUser } = useContext(AuthContext); // Use the setUser function from your AuthContext
  const [loginUser, { isLoading }] = useLoginMutation();

  const initialValues = {
    email: decryption(localStorage.getItem("email")) ?? "",
    password: decryption(localStorage.getItem("pwd")) ?? "",
    rememberMe: localStorage?.getItem("pwd") ? true : false,
  };

  const handleSubmit = (values) => {
    const formData = {
      email: values?.email,
      password: values?.password,
    };
    loginUser(formData)?.then((res) => {
      if (res?.data?.type === "success") {
        if (values?.rememberMe === true) {
          let email = encryption(values?.email);
          let pwd = encryption(values?.password);
          localStorage.setItem("email", email);
          localStorage.setItem("pwd", pwd);
        } else {
          localStorage.removeItem("email");
          localStorage.removeItem("pwd");
        }
        if (res.data?.data?.user?.role === "admin") {
          localStorage.setItem("USER_ROLE", "Admin");
          // setUser("Admin");
        } else {
          localStorage.setItem("USER_ROLE", "User");
          // setUser("User");
        }
        // setUser(res.data?.data?.user); // Assuming your API response returns user data in res.data.user
        dispatch(setCredentials(res.data?.data));
        navigate("/dashboard");
      } else {
        showErrorMsg(res?.data?.message);
      }
    });
  };
  return (
    <>
      <PageTitle title="Login" />
      <Container>
        <div className="d-flex flex-column justify-content-center">
          <div className="text-center my-5">
            {/* <img src={logo} alt="" height="60" /> */}
            <span className="logo-lg ">
              <img src={clientLogo} alt="" height="95" />
            </span>
          </div>
          <div className="my-5 d-flex flex-column flex-lg-row justify-content-center gap-2 gap-lg-0">
            <Card className="w-100 w-lg-50 my-auto rounded-0">
              <Card.Body className="p-lg-5 p-4">
                <div className="text-center">
                  <h5>Welcome Back !</h5>
                  <h5 className="text-muted mt-2"> Sign in to your account</h5>
                </div>

                <Formik
                  initialValues={initialValues}
                  validationSchema={loginFormValidation()}
                  onSubmit={handleSubmit}
                >
                  {({
                    errors,
                    touched,
                    values,
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    setFieldTouched,
                  }) => (
                    <Form
                      id="form1"
                      name="form1"
                      action="#"
                      className="mt-4"
                      onSubmit={handleSubmit}
                    >
                      <TextField
                        label="Email"
                        name="email"
                        value={values?.email}
                        placeholder="Enter Email"
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        validationMsg={<ErrorMessage name="email" />}
                      />
                      <PasswordField
                        label="Password"
                        name="password"
                        value={values?.password}
                        placeholder="Enter Password"
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        validationMsg={<ErrorMessage name="password" />}
                      />

                      <div className="d-flex align-items-center justify-content-between my-2">
                        <CheckboxField
                          label={
                            <div className="fs-14 text-muted">Remember Me</div>
                          }
                          name={"rememberMe"}
                          value={values?.rememberMe}
                          handleChange={handleChange}
                        />
                        <Link
                          to="/forgot-password"
                          style={{ width: "178px" }}
                          className="mb-2 fw-medium text-primary text-decoration-underline mt-3"
                        >
                          Forgot password?
                        </Link>
                      </div>
                      <ProcessButton
                        label={"Sign in"}
                        type={"submit"}
                        isLoading={isLoading}
                      />
                    </Form>
                  )}
                </Formik>
              </Card.Body>
            </Card>
            <div className="w-100 w-lg-50 mb-0">
              <AuthCarousel />
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default withRouter(Login);
