import { Route, Routes } from "react-router-dom";
import { authProtectedRoutes, publicRoutes } from "routes/allRoutes";
// import { AuthLayout, NoAuthLayout } from "routes/ProtectedRoute";
import NoAuthLayout from "components/layout/noAuthLayout/NoAuthLayout";
import AuthLayout from "components/layout/authLayout/AuthLayout";

const Index = () => {
  return (
    <Routes>
      {publicRoutes.map((route, idx) => (
        <Route
          key={idx}
          path={route?.path}
          element={<NoAuthLayout>{route?.component}</NoAuthLayout>}
        />
      ))}

      {authProtectedRoutes?.map((route, idx) => (
        <Route key={idx} path={route.path} exact={route.exact}>
          <Route index element={<AuthLayout>{route.component} </AuthLayout>} />

          {route?.subRoute?.map((subRoute, subRouteIndex) => {
            return (
              <Route
                key={subRouteIndex}
                path={subRoute?.path}
                element={<AuthLayout>{subRoute.component} </AuthLayout>}
              />
            );
          })}
        </Route>
      ))}
    </Routes>
  );
};

export default Index;
