import Tooltips from "../tooltipBox/Tooltip";
import customer from "../../../assets/images/customer.png";
import leads from "../../../assets/images/leads.png";
import { setBodyForCustomerApi } from "modules/helper";
import { formatDateCustomerFilter } from "../CollumnFormator";
export const UserDrawerFooter = ({
  pageData,
  identity,
  getCustomers,
  getLead,
  isCustomerDataLoading,
  isLeadLoading,
  mapFilters,
  setLeadData,
  customerData,
  leadsData,
  setMarker,
  customScroll,
}) => {
  const handleNextAndPreviousClick = (type) => {
    let pageNumber = null;
    customScroll();
    if (type === "next") {
      pageNumber = pageData?.currentPage + 1;
    } else {
      pageNumber = pageData?.currentPage - 1;
    }
    setMarker(null);
    let data = setBodyForCustomerApi(mapFilters);
    let leadsData = {};
    if (mapFilters?.location) {
      leadsData = {
        ...leadsData,
        location: { ...mapFilters?.location },
      };
    }
    if (mapFilters["start date"] !== "" && mapFilters["end date"] !== "") {
      let startDate = formatDateCustomerFilter(mapFilters["start date"]);
      let endDate = formatDateCustomerFilter(mapFilters["end date"]);
      leadsData = {
        ...leadsData,
        startDate: startDate,
        endDate: endDate,
      };
      data = {
        ...data,
        startDate,
        endDate,
      };
    }

    identity === "Leads"
      ? getLead({
          url: `sage/leads?page=${pageNumber}`,
          data: leadsData,
        }).then((res) => {
          setLeadData(() => {
            return res?.data;
          });
        })
      : getCustomers({
          url: `sage/customers?page=${pageNumber}`,
          data: data,
        });
  };
  return (
    <div className="offcanvas-footer border-top p-3 bg-light  text-center bottom-0 end-0">
      {!isCustomerDataLoading && !isLeadLoading && (
        <div>
          <p className="card-text text-left" style={{ paddingBottom: "1pc" }}>
            <span style={{ float: "left" }}>
              <Tooltips msg={"pages"}>
                <span>
                  {pageData?.currentPage}/{pageData?.pageLimit}
                </span>
              </Tooltips>
            </span>
            <img
              src={identity === "Leads" ? leads : customer}
              style={{ height: "24px" }}
              alt={identity}
            />{" "}
            Showing{" "}
            <b>
              {" "}
              {identity === "Leads"
                ? leadsData?.data?.leads?.length?.toLocaleString("en-US")
                : customerData?.data?.customers?.length?.toLocaleString(
                    "en-US"
                  )}{" "}
            </b>{" "}
            of <b>{pageData?.total?.toLocaleString("en-US")} </b>{" "}
          </p>
        </div>
      )}
      <div className="row">
        <div className="col-6">
          <button
            type="button"
            className="btn btn-primary w-100"
            id="reset-layout"
            disabled={
              pageData?.currentPage > 1
                ? false
                : true || isCustomerDataLoading || isLeadLoading
            }
            style={{
              cursor: pageData?.currentPage > 1 ? "pointer" : "not-allowed",
              backgroundColor: pageData?.currentPage > 1 ? "" : "gray",
            }}
            onClick={() => {
              handleNextAndPreviousClick("prev");
            }}
          >
            Previous
          </button>
        </div>
        <div className="col-6">
          <span
            className="btn btn-primary w-100"
            disabled={
              pageData?.currentPage < pageData?.pageLimit
                ? false
                : true || isCustomerDataLoading || isLeadLoading
            }
            onClick={() => {
              if (
                pageData?.currentPage < pageData?.pageLimit &&
                !isCustomerDataLoading &&
                !isLeadLoading
              ) {
                handleNextAndPreviousClick("next");
              }
            }}
            style={{
              cursor:
                pageData?.currentPage < pageData?.pageLimit
                  ? "pointer"
                  : "not-allowed",
              background:
                pageData?.currentPage < pageData?.pageLimit ? "" : "gray",
            }}
          >
            Next
          </span>
        </div>
      </div>
    </div>
  );
};
