import moment from "moment-timezone";

const formatDate = (date, format = "MM DD YYYY") => {
  let newDate = new Date(date);
  let zone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  // Customize the format string to include the correct AM/PM indicator
  const formattedDate = moment(newDate)
    ?.tz(zone)
    ?.format(format.replace(/a/g, "A"));

  return formattedDate;
  // return moment(date).format(format);
};
const DateFormat = (cell) => {
  return cell.value || "-";
};

const formatDateCustomerFilter = (date) => {
  return moment(date).format("YYYY-MM-DD");
};
export { formatDateCustomerFilter, formatDate, DateFormat };
