import React from "react";
import { Modal, Button, ModalHeader, ModalBody } from "react-bootstrap";

export const PauseAndResumeModal = ({
  show,
  data,
  handleClose,
  deleteModalFunction,
  text,
}) => {
  return (
    <React.Fragment>
      <Modal
        show={show}
        onHide={handleClose}
        id="removeCartModal"
        className="fade zoomIn"
        dialogClassName="modal-dialog-centered"
      >
        <ModalHeader closeButton></ModalHeader>
        <ModalBody className="p-md-5">
          <div className="text-center">
            <div>
              <button
                className={`btn btn-soft-${!data ? "warning" : "info"} btn-sm d-inline-block`}
                type="button"
              >
                <i
                  className={`ri ${
                    data ? " ri-notification-4-fill" : " ri-notification-off-fill"
                  }  display-5`}
                ></i>
              </button>
            </div>
            <div className="mt-4">
              <h4>Are you sure ?</h4>
              <p className="text-muted mx-4 mb-0">
                Are you sure you want to {data ? "resume" : "pause"} this {text}{" "}
                ?
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <Button
              type="button"
              variant="light"
              className="btn w-sm"
              onClick={handleClose}
            >
              Close
            </Button>
            <Button
              type="button"
              variant={!data ? "warning" : "info"}
              className="btn w-sm btn-hover"
              id="delete-record"
              onClick={deleteModalFunction}
            >
              {data ? "Resume" : "Pause"} , It!
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};
