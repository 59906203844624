import { DrawingManager } from "@react-google-maps/api";
import {
  polygonOptions,
  showWarningMsg,
  validatePolygon,
} from "modules/helper";
import React, { useEffect, useRef } from "react";

const DrawingManagerComponent = ({
  setIsFilterApplied,
  currentPolygon,
  isHeader,
  setCustomerFilter,
  setCurrentPolygon,
  setCircleData,
  clearAllMarkersAndCluster,
  setPolygonData,
  setInvalidCoordinates,
  mapFilters,
  setMapFilters,
  setMarker,
  invalidCoordinates,
}) => {
  // Update the ref whenever invalidCoordinates changes
  useEffect(() => {
    invalidCoordinatesRef.current = invalidCoordinates;
  }, [invalidCoordinates]);
  const drawingManagerRef = useRef();
  const invalidCoordinatesRef = useRef(invalidCoordinates);
  const onLoadDrawingManager = (drawingManager) => {
    drawingManagerRef.current = drawingManager;

    window.google.maps.event.addListener(
      drawingManagerRef.current,
      "drawingmode_changed",
      () => {
        const drawingMode = drawingManagerRef.current.getDrawingMode();
        // if we have invalid polygons then clear previouse polygons
        let currentInvalidCoordinates = invalidCoordinatesRef.current;

        if (currentInvalidCoordinates?.length > 0) {
          setInvalidCoordinates([]);
          setCurrentPolygon((currentPolygon) => currentPolygon?.setMap(null));
        }
        if (drawingMode !== window.google.maps.drawing.OverlayType.POLYGON) {
          if (currentPolygon) {
            setMapFilters((prev) => ({ ...prev, area: null, location: null }));
            currentPolygon.setMap(null);
            if (isHeader) {
              setCustomerFilter(false);
            }
            setCurrentPolygon(null);
          }
        }
        if (drawingMode && window.google.maps.drawing.OverlayType.POLYGON) {
          setIsFilterApplied(false);
          // Remove any previously drawn polygon or other shapes
          setCurrentPolygon((currentPolygon) => currentPolygon?.setMap(null));
          setMapFilters((prev) => ({
            ...prev,
            isFilterApplied: false,
            area: null,
            location: null,
          }));

          setCircleData({});
          if (isHeader) {
            setCustomerFilter(false);
          }
          // setting marker null separate from clearAllMarkersAndCluster() method
          setMarker(null);
          clearAllMarkersAndCluster();
          setPolygonData([]);
        }
        // Remove any previously drawn polygon or other shapes
        if (currentPolygon) {
          currentPolygon.setMap(null);
          setCurrentPolygon(null);
          if (isHeader) {
            setCustomerFilter(false);
          }
          setCircleData({});
          setPolygonData([]);
        }
      }
    );
  };
  const onOverlayComplete = ($overlayEvent) => {
    // Remove existing polygon if it exists
    if (currentPolygon) {
      currentPolygon.setMap(null);
    }
    // Set the new polygon
    const newPolygon = $overlayEvent.overlay;
    newPolygon.addListener("click", () => {});
    setCurrentPolygon(newPolygon);
    // Set drawing mode to null to stop drawing new shapes
    drawingManagerRef.current.setDrawingMode(null);
  };

  const drawingManagerOptions = {
    polygonOptions: polygonOptions,
    drawingControl: true,
    drawingControlOptions: {
      position: window?.google?.maps?.ControlPosition?.LEFT_TOP,
      drawingModes: [
        window?.google?.maps?.drawing?.OverlayType?.CIRCLE,
        window?.google?.maps?.drawing?.OverlayType?.POLYGON,
      ],
    },
    circleOptions: {
      fillOpacity: 0.3,
      fillColor: "#FFFFFF",
      strokeColor: "#ff0000",
      strokeWeight: 2,
      zIndex: 1,
      clickable: false,
    },
  };

  const handlePolygons = (polygon) => {
    let coordinates = polygon
      .getPath()
      .getArray()
      .map((item) => [item.lng(), item.lat()]);
    if (coordinates.length === 1) {
      showWarningMsg("Please Select at least 3 points");
      return;
    }
    if (coordinates.length < 3) {
      showWarningMsg("Please Select at least 3 points");
      setInvalidCoordinates(() => {
        return [...coordinates, coordinates[0]];
      });
      return;
    }
    const validation = validatePolygon([...coordinates, coordinates[0]]);
    if (!validation) {
      if (setInvalidCoordinates) {
        setInvalidCoordinates(() => {
          return [...coordinates, coordinates[0]];
        });
      }
      showWarningMsg("Invalid polygon");
      return;
    }
    setMapFilters({
      ...mapFilters,
      location: {
        type: "polygon",
        coordinates: [...coordinates, coordinates[0]],
      },
      isFilterApplied: true,
    });
    if (setInvalidCoordinates) {
      setInvalidCoordinates([]);
    }
  };
  const handleCircles = (circle) => {
    let coordinates = [[circle.center.lng(), circle.center.lat()]];
    setMapFilters({
      ...mapFilters,
      location: {
        type: "circle",
        radius: circle.radius * 0.000621371, //convert to miles
        coordinates: [...coordinates],
      },
      isFilterApplied: true,
    });
    if (setInvalidCoordinates) {
      setInvalidCoordinates([]);
    }
  };
  return (
    <div className="drawing-manager">
      <DrawingManager
        onLoad={onLoadDrawingManager}
        onOverlayComplete={onOverlayComplete}
        options={drawingManagerOptions}
        ref={drawingManagerRef}
        onPolygonComplete={(e) => handlePolygons(e)}
        onCircleComplete={(e) => handleCircles(e)}
      />
    </div>
  );
};

export default DrawingManagerComponent;
