import React, { useEffect, useState } from "react";
import { Button, Dropdown, Image } from "react-bootstrap";
//import images
import dummy from "assets/images/users/user-dummy-img.jpg";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { getAuthToken, removeAuthToken } from "modules/util";
import { useNavigate } from "react-router-dom";
import { showSuccessMsg } from "modules/helper";
import { useLogoutMutation } from "reduxStore/rtk/EndPoints/LoginEndPoint/LoginSlice";

import {
  logOut,
  selectCurrentToken,
} from "reduxStore/rtk/EndPoints/LoginEndPoint/authSlice";
import { useUserProfileQuery } from "reduxStore/rtk/EndPoints/LoginEndPoint/userProfileSlice";
import ProfileModal from "./ProfileModal";
import ChangePasswordModal from "./ChangePasswordModal";

const ProfileDropdown = () => {
  const [userName, setUserName] = useState("Admin");
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);

  const { data: userData } = useUserProfileQuery();
  const navigate = useNavigate();
  const [logoutUser] = useLogoutMutation();
  const dispatch = useDispatch();
  // const {user}=useAuth() // get user from context
  const { logout: loggedUser } = useSelector((state) => state.auth);
  const token = useSelector(selectCurrentToken);

  const profiledropdownData = createSelector(
    (state) => state.rootReducerProfile,
    (user) => user
  );
  // Inside your component
  const user = useSelector(profiledropdownData);
  useEffect(() => {
    const authUser = sessionStorage.getItem("authUser");
    if (authUser) {
      // const obj = JSON.parse(authUser);
      setUserName(
        userData?.data?.username ? userData?.data?.username : "Admin"
      );
    }
    // eslint-disable-next-line
  }, [userName, user]);
  const handleLogout = async () => {
    let res = await logoutUser({
      refreshToken: getAuthToken() ? getAuthToken() : "",
      token: token,
    });
    dispatch(logOut({ selfLogout: true }));
    removeAuthToken();
    if (res?.data?.type === "success") {
      showSuccessMsg(res?.data?.message);
      navigate("/login");
    }
  };

  const handelProfile = () => {
    setShowProfileModal(!showProfileModal);
  };

  const handleChangePassword = () => {
    setShowChangePasswordModal(!showChangePasswordModal);
  };
  return (
    <React.Fragment>
      <Dropdown className="header-item">
        <Dropdown.Toggle
          type="button"
          className="btn bg-transparent border-0 arrow-none"
          id="page-header-user-dropdown"
        >
          <span className="d-flex align-items-center text-dark">
            <Image
              src={
                userData?.data?.profileImage
                  ? `${process.env.REACT_APP_IMAGE_BASE_URL}profile/${userData?.data?.profileImage}`
                  : dummy
              }
              alt="Header Avatar"
              id="header-avatar"
              className="rounded-circle header-profile-user"
              // setting fallback image if src image doesn't load
              onError={(event) => {
                event.currentTarget.src = dummy;
                event.currentTarget.onerror = null;
              }}
            />
            <span className="text-start ms-xl-2">
              <span className="d-none d-xl-inline-block fw-medium user-name-text fs-16">
                {!loggedUser ? userData?.data?.name : ""}
                <i className="las la-angle-down fs-12 ms-1"></i>
              </span>
            </span>
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu-end">
          <Button className="dropdown-item" onClick={handelProfile}>
            {/* <Link to="/profile" className="dropdown-item"> */}
            {/* <Dropdown.Item className="dropdown-item"> */}
            <i className="bx bx-user fs-15 align-middle me-1"></i>{" "}
            <span key="t-profile">Profile</span>
            {/* </Dropdown.Item> */}
            {/* </Link> */}
          </Button>
          <Button className="dropdown-item" onClick={handleChangePassword}>
            <i className="bx bx-lock-open fs-15 align-middle me-1"></i>{" "}
            <span key="t-change-password">Change Password</span>
          </Button>
          {/* <Button className="dropdown-item d-block" href="#">
            <span className="badge bg-success float-end">11</span>
            <i className="bx bx-wrench fs-15 align-middle me-1"></i>{" "}
            <span key="t-settings">Settings</span>
          </Button> */}
          {/* <Button
            className="dropdown-item"
            href={process.env.PUBLIC_URL + "/auth-lockscreen"}
          >
            <i className="bx bx-lock-open fs-15 align-middle me-1"></i>{" "}
            <span key="t-lock-screen">Lock screen</span>
          </Button> */}
          <div className="dropdown-divider"></div>
          <Dropdown.Item
            className="dropdown-item text-danger"
            onClick={handleLogout}
          >
            <i className="bx bx-power-off fs-15 align-middle me-1 text-danger"></i>{" "}
            <span key="t-logout">Logout</span>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <ProfileModal
        isShow={showProfileModal}
        handleClose={() => setShowProfileModal(false)}
      />
      <ChangePasswordModal
        isShow={showChangePasswordModal}
        handleClose={() => setShowChangePasswordModal(false)}
      />
    </React.Fragment>
  );
};

export default ProfileDropdown;
