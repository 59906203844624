import { showSuccessMsg } from "modules/helper";
import React from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Spinner,
} from "react-bootstrap";
import { useSetStatusMutation } from "reduxStore/rtk/EndPoints/UserEndPoint/UserSlice";

const UserStatusModal = ({ show, handleClose, user }) => {
  const [setUserStatus, {  isLoading }] = useSetStatusMutation(); // api to handle user status

  const toggleStatus = () => {
    let userData = {
      isActive: !user?.isActive,
    };
    try {
      setUserStatus({ url: `/users/status/${user._id}`, data: userData }) // if data is active status will be set to active or vice-versa
        .then((res) => {
          // after success show toast and close modal
          if (res?.data?.type === "success") {
            showSuccessMsg(res?.data?.message);
            handleClose();
          }
        })
        .finally(() => handleClose());
    } catch (error) {
      console.log(error.message);
    }
  };
  return (
    <React.Fragment>
      <Modal
        show={show}
        onHide={handleClose}
        id="removeCartModal"
        className="fade zoomIn"
        dialogClassName="modal-dialog-centered"
      >
        <ModalHeader closeButton></ModalHeader>
        <ModalBody className="p-md-5">
          <div className="text-center">
            <div
              className={`${user?.isActive ? "text-danger" : "text-success"}`}
            >
              <i
                className={`bi bi-toggle-${user.isActive ? "off" : "on"} display-5`}
              ></i>
            </div>
            <div className="mt-4">
              <h4>Are you sure ? </h4>
              <p className="text-muted mx-4 mb-0">
                Are you sure you want to
                <b> {user.isActive ? "In Activate" : "Activate"} </b> user ?
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <Button
              type="button"
              variant="light"
              className="btn w-sm"
              onClick={handleClose}
            >
              No
            </Button>
            <Button
              type="button"
              variant={`${user.isActive ? "danger" : "primary"}`}
              className="btn w-sm btn-hover"
              id="delete-record"
              onClick={toggleStatus}
              disabled={isLoading}
            >
              {isLoading && <Spinner size="sm" />}
              Yes
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default UserStatusModal;
