import { silverTheme } from "assets/mapThemes/silverTheme";
import { darkTheme } from "assets/mapThemes/darkTheme";
import { nightTheme } from "assets/mapThemes/nightTheme";
import { aubergineTheme } from "assets/mapThemes/aubergineTheme";
import { retroTheme } from "assets/mapThemes/retroTheme";
import standardThemeImg from "assets/images/mapThemesImages/standardTheme.png";
import darkThemeImg from "assets/images/mapThemesImages/dark.png";
import aubergineThemeImg from "assets/images/mapThemesImages/aubergine.png";
import nightThemeImg from "assets/images/mapThemesImages/night.png";
import retroThemeImg from "assets/images/mapThemesImages/retro.png";
import silverThemeImg from "assets/images/mapThemesImages/silver.png";

export const themes = [
    {
        id: "standard",
        name: "Standard",
        theme: "",
        themeImage: standardThemeImg
    },
    {
        id: "silver",
        name: "Silver",
        theme: silverTheme,
        themeImage: silverThemeImg
    },
    {
        id: "dark",
        name: "Dark",
        theme: darkTheme,
        themeImage: darkThemeImg
    },
    {
        id: "retro",
        name: "Retro",
        theme: retroTheme,
        themeImage: retroThemeImg,
    },
    {
        id: "night",
        name: "Night",
        theme: nightTheme,
        themeImage: nightThemeImg
    },
    {
        id: "aubergine",
        name: "Aubergine",
        theme: aubergineTheme,
        themeImage: aubergineThemeImg
    }
]