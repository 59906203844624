import ForgotPassword from "components/layout/noAuthLayout/ForgotPassword";
import Login from "components/layout/noAuthLayout/Login";
import ResetPassword from "components/layout/noAuthLayout/ResetPassword";
import Page500 from "components/pages/ErrorPages/Page500";
import Dashboard from "components/pages/dashboard/Index";
import Notification from "components/pages/notification/Index";
import Users from "components/pages/user";
import NotificationReports from "components/pages/notificationReports/NotificationReports";
import { Page404 } from "components/pages/ErrorPages/Page404";

//IMP NOTE :: while creating new routes , don't forget to add route in AuthLayout.jsx
// routing will not work until you add route in AuthLayout.jsx

const authProtectedRoutes = [
  {
    //home page
    path: "/dashboard",
    component: <Dashboard />,
    role: ["Super Admin", "Admin", "User"],
  },
  {
    //user list tables
    path: "/users",
    component: <Users />,
    role: ["Super Admin", "Admin"],
  },
  {
    //notification list table
    path: "/notification",
    component: <Notification />,
    role: ["Super Admin", "Admin", "User"],
  },
  {
    // user notified report list
    path: "/reports",
    component: <NotificationReports />,
    role: ["Super Admin", "Admin", "User"],
  },
];

const publicRoutes = [
  { path: "/", component: <Login /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgotPassword /> },
  { path: "/v1/auth/reset-password", component: <ResetPassword /> },
  { path: "/server-error", component: <Page500 /> },
  { path: "*", component: <Page404 /> },
];

export { authProtectedRoutes, publicRoutes };
