import PageTitle from "components/common/PageTitle";
import UserMap from "./UserMap";

const Dashboard = () => {
  return (
    <>
      <PageTitle title="Dashboard" />
      {/* <BreadCrumb pageTitle="Dashboard" title="Dashboard" /> */}
      <UserMap />
    </>
  );
};

export default Dashboard;
