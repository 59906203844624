const LAYOUT_TYPES = {
  VERTICAL: "vertical",
  HORIZONTAL: "horizontal",
};

const LAYOUT_MODE_TYPES = {
  LIGHTMODE: "light",
  DARKMODE: "dark",
};

const LAYOUT_WIDTH_TYPES = {
  FLUID: "fluid",
  BOXED: "boxed",
};

const LAYOUT_POSITION_TYPES = {
  FIXED: "fixed",
  SCROLLABLE: "scrollable",
};

const LAYOUT_TOPBAR_THEME_TYPES = {
  LIGHT: "light",
  DARK: "dark",
};

const LEFT_SIDEBAR_SIZE_TYPES = {
  DEFAULT: "sm",
  COMPACT: "md",
  SMALLICON: "sm",
  SMALLHOVER: "sm-hover",
};

const LEFT_SIDEBAR_VIEW_TYPES = {
  DEFAULT: "default",
  DETACHED: "detached",
};

const LAYOUT_SIDEBAR_TYPES = {
  LIGHT: "light",
  DARK: "dark",
};

export {
  LAYOUT_TYPES,
  LAYOUT_MODE_TYPES,
  LAYOUT_SIDEBAR_TYPES,
  LAYOUT_WIDTH_TYPES,
  LAYOUT_POSITION_TYPES,
  LAYOUT_TOPBAR_THEME_TYPES,
  LEFT_SIDEBAR_SIZE_TYPES,
  LEFT_SIDEBAR_VIEW_TYPES,
};
