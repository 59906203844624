import { useEffect, useState } from "react";
import Tooltips from "components/common/tooltipBox/Tooltip";
import { Button, Card, Spinner } from "react-bootstrap";
import { useGetWeatherDataMutation } from "reduxStore/rtk/EndPoints/WeatherEndPoint/weatherSlice";
import WeatherHistoryInfoWindow from "./InfowindowHeader/WeatherHistoryInfoWindow";
import { useGetNearbySalesmanMutation } from "reduxStore/rtk/EndPoints/SageEndPoint/sageSlice";
import NearbySalesPersonInfoWindow from "./InfowindowHeader/NearbySalesPersonInfoWindow";
import RangeSlider from "components/common/slider/RangeSlider";
import { convertToExcel } from "modules/helper";
import { useAuth } from "components/context/UserContext";

const InfoWindowHeader = ({
  marker,
  setMarker,
  weatherFilters,
  setWeatherFilters,
  salesFilter,
  setSalesFilter,
}) => {
  const { user } = useAuth();
  const [activeTab, setActiveTab] = useState("");
  const [range, setRange] = useState([0, 5]);
  const [getWeatherData, { isLoading: isWeatherHistoryLoading }] =
    useGetWeatherDataMutation();
  const [getNearbySalesPerson, { isLoading: isNearbySalesPersonDataLoading }] =
    useGetNearbySalesmanMutation();
  const [weatherData, setWeatherData] = useState(null);
  const [nearbySalesPersonData, setNearbySalesPersonData] = useState(null);

  const isApiCalling =
    isWeatherHistoryLoading || isNearbySalesPersonDataLoading;
  useEffect(() => {
    if (activeTab === "nearby-sales-person") {
      fetchData();
    }
  }, [salesFilter]);
  const handleDownload = async () => {
    try {
      let res = await getNearbySalesPerson({
        url: "sage/nearby-sales-person",
        data: {
          latitude: marker.lat,
          longitude: marker.lng,
          minMiles: range[0],
          maxMiles: range[1],
        },
        export: true,
      });
      convertToExcel(res?.data?.data);
    } catch (error) {
      console.error("Error exporting data:", error);
    }
  };
  const fetchData = async () => {
    try {
      let res = await getNearbySalesPerson({
        url: "sage/nearby-sales-person",
        data: {
          latitude: marker.lat,
          longitude: marker.lng,
          minMiles: range[0],
          maxMiles: range[1],
        },
      });
      setNearbySalesPersonData(res.data?.data);
    } catch (error) {
      console.error(error.message);
    }
  };
  const handleSalesFilter = async () => {
    setActiveTab("nearby-sales-person");
    if (activeTab === "nearby-sales-person") return;
    fetchData();
  };

  const getImpactedArea = async () => {
    setActiveTab("weather-data");
    if (weatherData !== null) return;
    let res = await getWeatherData({
      url: "weather/affected-locations",
      data: {
        location: {
          lat: marker?.lat,
          lang: marker?.lng,
        },
      },
    });
    const formattedData = Object.entries(res?.data?.data)
      .map((data) => {
        return { date: data[0], ...data[1] };
      })
      .sort((a, b) => new Date(Number(b.date)) - new Date(Number(a.date))); // Ensure using the correct key
    setWeatherData(formattedData);
  };

  return (
    <div
      className="search-location-info-window m-0 p-0"
      style={{
        height:
          weatherData || nearbySalesPersonData || isApiCalling
            ? "360px"
            : "auto",
        width:
          weatherData || nearbySalesPersonData || isApiCalling
            ? "980px"
            : "350px",
      }}
    >
      <Card className="m-0 p-0 border-0 shadow-none">
        <Card.Body className="p-0 m-0 border-0 shadow-none">
          <div className="d-flex align-items-center bg-white  sticky-top">
            <div className="flex-shrink-0">
              <i
                className="bi bi-geo-alt-fill fs-4"
                style={{ color: "#00529D" }}
              ></i>
            </div>
            <div className="flex-grow-1 ms-2 align-self-center">
              <h5 className="card-title m-0 fs-6">{marker?.location}</h5>
            </div>
            <Tooltips msg={"Find Nearby Sales Person"}>
              <Button
                disabled={isApiCalling}
                onClick={handleSalesFilter}
                className={`btn btn-subtle-primary ${activeTab === "nearby-sales-person" && "active"}`}
              >
                {isNearbySalesPersonDataLoading ? (
                  <Spinner size="sm" />
                ) : (
                  <i className="bx bx-user-check fs-5"></i>
                )}
              </Button>
            </Tooltips>
            <Tooltips msg={"Weather History"}>
              <Button
                disabled={isApiCalling}
                onClick={getImpactedArea}
                className={`btn btn-subtle-primary mx-1 ${activeTab === "weather-data" && "active"}`}
              >
                {isWeatherHistoryLoading ? (
                  <Spinner size="sm" />
                ) : (
                  <i className="mdi mdi-cloud-search fs-6"></i>
                )}
              </Button>
            </Tooltips>
            <Tooltips msg={"Close"} direction={"bottom"}>
              <Button
                disabled={isApiCalling}
                className="btn btn-subtle-danger"
                onClick={() => setMarker(null)}
              >
                <i className="ri-close-circle-line fs-6"></i>
              </Button>
            </Tooltips>
          </div>

          {weatherData !== null && activeTab === "weather-data" && (
            <div className="mt-1">
              <WeatherHistoryInfoWindow
                weatherData={weatherData}
                setWeatherData={setWeatherData}
                location={marker?.location}
                tableHeight={"250px"}
                weatherFilters={weatherFilters}
                setWeatherFilters={setWeatherFilters}
              />
            </div>
          )}

          {nearbySalesPersonData !== null &&
            !isNearbySalesPersonDataLoading &&
            activeTab === "nearby-sales-person" && (
              <div className="mt-1">
                <NearbySalesPersonInfoWindow
                  salesData={nearbySalesPersonData}
                  marker={marker}
                  showDownloadButton={false}
                />
              </div>
            )}

          {isApiCalling && (
            <>
              <div className="table-skeleton">
                <div className="table-body">
                  {[...Array(8)].map((_, index) => (
                    <div className="table-row placeholder-glow m-1" key={index}>
                      <div
                        className="placeholder col-3 "
                        style={{ height: "27px" }}
                      ></div>
                      <div
                        className="placeholder col-3 "
                        style={{ height: "27px" }}
                      ></div>
                      <div
                        className="placeholder col-3 "
                        style={{ height: "27px" }}
                      ></div>
                      <div
                        className="placeholder col-3 "
                        style={{ height: "27px" }}
                      ></div>
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}
          {activeTab === "nearby-sales-person" && (
            <div className="d-flex align-items-center justify-content-between">
              <RangeSlider
                range={range}
                setRange={setRange}
                disabled={isNearbySalesPersonDataLoading}
                setSalesFilter={setSalesFilter}
                salesFilter={salesFilter}
              />
              {(!user?.salesId || (user?.salesId && user?.allowExport)) && (
                <div className="text-end mt-3">
                  <Tooltips msg={"Download Data"}>
                    <Button
                      disabled={
                        isNearbySalesPersonDataLoading ||
                        !nearbySalesPersonData?.length
                      }
                      onClick={handleDownload}
                      className="btn btn-success"
                    >
                      {isNearbySalesPersonDataLoading ? (
                        <Spinner size="sm" />
                      ) : (
                        <i className="bi bi-file-earmark-excel-fill fs-6"></i>
                      )}
                    </Button>
                  </Tooltips>
                </div>
              )}
            </div>
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default InfoWindowHeader;
