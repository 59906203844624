import { AuthProvider } from "components/context/UserContext";
import Layout from "../index";
import { getAuthToken } from "modules/util";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// this is middleware use to authenticate routes.
const AuthLayout = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location?.pathname;
  const isLoggedIn = !!getAuthToken();

  const adminRoutes = [
    "/dashboard",
    "/users",
    "/notification",
    "/profile",
    "/reports",
  ];
  const userRoutes = ["/dashboard", "/notification", "/profile", "/reports"];
  // const commonRoutes = [
  //   "/dashboard",
  //   // "/profile"
  // ];

  // const isCommonRoutes = commonRoutes.find((items) =>
  //   pathname?.startsWith(items)
  // );
  const isAdminRoutes = adminRoutes.find((items) =>
    pathname?.startsWith(items)
  );
  const isUseRoutes = userRoutes.find((items) => pathname?.startsWith(items));
  const isUser =
    localStorage.getItem("USER_ROLE") &&
    localStorage.getItem("USER_ROLE") === "User";

  const isAdmin =
    localStorage.getItem("USER_ROLE") &&
    localStorage.getItem("USER_ROLE") === "Admin";

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login");
    } else {
      window.scrollTo({ top: 0 });
      if (isAdmin) {
        if (!isAdminRoutes) {
          navigate("/dashboard");
        }
      } else if (isUser) {
        if (!isUseRoutes) {
          navigate("/dashboard");
        }
      } else {
        navigate("/login");
      }
    }
    // eslint-disable-next-line
  }, [navigate]);

  return (
    <>
      <AuthProvider>
        <Layout>{children}</Layout>
      </AuthProvider>
    </>
  );
};

export default AuthLayout;
