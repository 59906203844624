import { InfoWindowF } from "@react-google-maps/api";
import SageNearbySalespersonModal from "components/common/modals/SageNearbySalespersonModal";
import SageWeatherDataModal from "components/common/modals/SageWeatherDataModal";
import Tooltips from "components/common/tooltipBox/Tooltip";
import { firstLetterUpperCase, initialSalesFilter } from "modules/helper";
import { useEffect, useState } from "react";
import { Button, Card, Spinner } from "react-bootstrap";
import { useGetNearbySalesmanMutation } from "reduxStore/rtk/EndPoints/SageEndPoint/sageSlice";
import { useGetWeatherDataMutation } from "reduxStore/rtk/EndPoints/WeatherEndPoint/weatherSlice";
export const MarkerComponent = ({
  setMarker,
  marker,
  weatherFilters,
  setWeatherFilters,
  salesFilter,
  setSalesFilter,
}) => {
  const [callApiFlag, setCallApiFlag] = useState({
    weatherData: true,
    salesData: true,
  });
  useEffect(() => {
    setCallApiFlag({
      weatherData: true,
      salesData: true,
    });
    setSalesFilter(initialSalesFilter);
  }, [marker]);
  const [weatherData, setWeatherData] = useState(null); // storing weather data
  const [nearbySalesPersonData, setNearbySalesPersonData] = useState(null); // storing nearby sales person data

  const [showSageWeatherDataModal, setShowSageWeatherDataModal] =
    useState(false); // weather data modal flag
  const [showSageNearbySalespersonModal, setShowSageNearbySalespersonModal] =
    useState(false);
  const [getWeatherData, { isLoading: isWeatherHistoryLoading }] =
    useGetWeatherDataMutation();
  const [getNearbySalesPerson, { isLoading: isNearbySalesPersonDataLoading }] =
    useGetNearbySalesmanMutation();
  //  formatting phone number
  let phoneNumber = marker?.phone?.trim();
  let areaCode = phoneNumber?.slice(0, 3);
  let firstThreeDigit = phoneNumber?.slice(3, 6);
  let lastFourDigit = phoneNumber?.slice(-4);

  // check wether api is calling to show loaders
  const isApiCalling =
    isWeatherHistoryLoading || isNearbySalesPersonDataLoading;
  // getting impacted area according to current location
  const getImpactedArea = async () => {
    try {
      if (callApiFlag?.weatherData) {
        let res = await getWeatherData({
          url: "weather/affected-locations",
          data: {
            location: {
              lat: marker?.latitude,
              lang: marker?.longitude,
            },
          },
        });
        const formattedData = Object.entries(res?.data?.data)
          .map((data) => {
            return { date: data[0], ...data[1] };
          })
          .sort((a, b) => new Date(Number(b.date)) - new Date(Number(a.date))); // Ensure using the correct key
        setWeatherData(formattedData);
        setCallApiFlag({ ...callApiFlag, weatherData: false });
      }
      setShowSageWeatherDataModal(true);
    } catch (error) {
      console.error(error);
    }
  };

  // get nearby sales person
  useEffect(() => {
    (async () => {
      if (!callApiFlag?.salesData) {
        fetchData();
      }
    })();
  }, [salesFilter]);
  const fetchData = async () => {
    try {
      let res = await getNearbySalesPerson({
        url: "sage/nearby-sales-person",
        data: {
          latitude: marker?.latitude,
          longitude: marker?.longitude,
          minMiles: salesFilter?.minMiles,
          maxMiles: salesFilter?.maxMiles,
        },
      });
      setNearbySalesPersonData(res.data?.data);
      setCallApiFlag({ ...callApiFlag, salesData: false });
    } catch (error) {
      console.error(error.message);
    }
  };
  const handleSalesFilter = async () => {
    if (callApiFlag?.salesData) {
      fetchData();
    }
    setShowSageNearbySalespersonModal(true);
  };
  return (
    <InfoWindowF
      position={{
        lat: marker?.latitude,
        lng: marker?.longitude,
      }}
      options={{
        ariaLabel: "CustomerLeadInfo",
        pixelOffset: new window.google.maps.Size(0, -38),
        headerDisabled: true,
      }}

      // onCloseClick={() => setMarker(null)}
    >
      <div className="customer-lead-info-window">
        <Card className="card-body mb-0  position-relative border border-0 shadow-none ">
          <div className="d-flex mb-2 align-items-center ">
            <div className="flex-shrink-0">
              <i
                className="bi bi-person-circle"
                style={{
                  color: "#00529D",
                  fontSize: "xx-large",
                }}
              ></i>
            </div>
            <div className="flex-grow-1 mx-2">
              <h5
                className="card-title mb-1 me-3 text-wrap"
                style={{ wordBreak: "break-all" }}
              >
                {marker?.name}
                <Tooltips
                  msg={marker?.customerId ? "Customer Id" : "Lead No"}
                  direction={"right"}
                >
                  <span
                    className="badge-label badge bg-primary"
                    style={{ fontSize: "x-small" }}
                  >
                    {marker?.customerId ?? marker?.leadId}
                  </span>
                </Tooltips>
              </h5>
              <div className="d-flex gap-2 my-1">
                {marker?.estimateAmount && (
                  <Tooltips msg={"Estimated Amount"} direction={"left"}>
                    {/* <i
                        className=" ri-money-dollar-circle-line m-0 p-0 fs-6"
                        style={{ color: "#00529D" }}
                      ></i> */}
                    <span className="text-dark " style={{ fontWeight: "800" }}>
                      ${marker?.estimateAmount?.toLocaleString("en-US")}
                    </span>
                  </Tooltips>
                )}
                <Tooltips msg={"Job Id"} direction={"left"}>
                  <p className="text-muted mb-0">{marker?.jobid}</p>
                </Tooltips>
                {marker?.leadNo && (
                  <Tooltips msg={"Lead No"} direction={"right"}>
                    <span
                      className="badge rounded-pill bg-primary-subtle text-primary"
                      style={{ fontSize: "x-small" }}
                    >
                      {marker?.leadNo}
                    </span>
                  </Tooltips>
                )}
              </div>
            </div>
            <span
              className=" text-muted float-right position-absolute"
              onClick={() => {
                if (!isApiCalling) {
                  setMarker(null);
                }
              }}
              style={{
                top: "14px",
                cursor: `${isApiCalling ? "wait" : "pointer"}`,
                right: " 0px",
                color: "gray",
              }}
            >
              <i
                className=" ri-close-circle-line"
                style={{ fontSize: "26px" }}
              />
            </span>
          </div>
          {marker?.material && (
            <>
              <Tooltips msg={"Material"} direction={"left"}>
                <h6 className="mb-1 " style={{ fontSize: "14px" }}>
                  <i
                    className="mdi mdi-home-roof"
                    style={{ color: "#00529D", fontSize: "18px" }}
                  ></i>
                  &nbsp;
                  {marker.material}
                </h6>
              </Tooltips>
              <hr className="my-2 p-0" />
            </>
          )}
          <Tooltips msg={"Location"} direction={"left"}>
            <h6 className="mb-2 mt-2">
              <i
                className=" ri-user-location-line"
                style={{ color: "#00529D" }}
              ></i>
              &nbsp;
              {firstLetterUpperCase(marker?.address)},
            </h6>
          </Tooltips>
          <Tooltips msg={"Location"} direction={"left"}>
            <h6 style={{ marginLeft: "20px" }}>
              {firstLetterUpperCase(marker?.city)},&nbsp;
              {marker?.state.toUpperCase()},&nbsp;
              {marker?.zipcode}
            </h6>
          </Tooltips>
          {marker?.email?.trim()?.length > 0 && (
            <Tooltips msg={"Email"} direction={"left"}>
              <h6 className="mb-2">
                <i className="bi bi-envelope" style={{ color: "#00529D" }}></i>
                &nbsp;
                <a
                  href={`mailto:${marker.email}`}
                  className="custom-email-mobileNumber"
                >
                  {marker.email}
                </a>
              </h6>
            </Tooltips>
          )}
          <div className="d-flex gap-5">
            {marker?.phone?.trim()?.length > 0 && (
              <Tooltips msg={"Phone"} direction={"left"}>
                <h6 className="mb-2">
                  <i
                    className="bi bi-telephone"
                    style={{ color: "#00529D" }}
                  ></i>
                  &nbsp;
                  <a
                    href={`tel:${marker.phone}`}
                    className="custom-email-mobileNumber"
                  >
                    {`(${areaCode}) ${firstThreeDigit}-${lastFourDigit}`}
                  </a>
                </h6>
              </Tooltips>
            )}
            <Tooltips msg={"Logged Date"} direction={"left"}>
              <h6 className="mb-0">
                <i
                  className="bi bi-calendar-date"
                  style={{ color: "#00529D" }}
                ></i>
                &nbsp;
                {marker.loggedDate}
              </h6>
            </Tooltips>
          </div>

          <hr className="my-2 p-0" />
          <div className="d-flex mt-2 align-items-center">
            <div className="flex-grow-1">
              <p className="card-text text-muted mb-1">
                <i
                  className="bx bx-user-check "
                  style={{ color: "#00529D" }}
                ></i>
                &nbsp; Assigned To
              </p>
              <h6 className="mb-0">
                {marker?.customerId ? marker.employee : marker?.assignedTo}
              </h6>
              {marker?.assigneeEmail && (
                <Tooltips msg={"Assignee Email"} direction={"left"}>
                  <h6
                    className="mb-2 fs-6 custom-email-mobileNumber"
                    style={{ cursor: "pointer" }}
                  >
                    <a
                      href={`mailto:${marker.assigneeEmail}`}
                      className="custom-email-mobileNumber"
                    >
                      {marker.assigneeEmail?.toLowerCase()}
                    </a>
                  </h6>
                </Tooltips>
              )}
            </div>
            <Tooltips msg={"Find Nearby Sales Person"}>
              <Button
                disabled={isApiCalling}
                onClick={handleSalesFilter}
                className={`btn btn-subtle-primary`}
              >
                {false ? (
                  <Spinner size="sm" />
                ) : (
                  <i className="bx bx-user-check fs-5"></i>
                )}
              </Button>
            </Tooltips>
            <Tooltips msg={"Weather History"}>
              <Button
                disabled={isApiCalling}
                onClick={getImpactedArea}
                className={`btn btn-subtle-primary mx-1 `}
              >
                {isWeatherHistoryLoading ? (
                  <Spinner size="sm" />
                ) : (
                  <i className="mdi mdi-cloud-search fs-6"></i>
                )}
              </Button>
            </Tooltips>
          </div>
        </Card>
        <SageWeatherDataModal
          show={showSageWeatherDataModal}
          closeNearbySalesModal={() => {
            setShowSageWeatherDataModal(false);
          }}
          salesFilter={salesFilter}
          setSalesFilter={setSalesFilter}
          weatherFilters={weatherFilters}
          setWeatherFilters={setWeatherFilters}
          weatherData={weatherData}
          setWeatherData={setWeatherData}
          location={marker?.address}
          address={{
            address: marker?.address,
            city: marker?.city,
            state: marker?.state,
            zipcode: marker?.zipcode,
          }}
        />

        <SageNearbySalespersonModal
          isNearbySalesPersonDataLoading={isNearbySalesPersonDataLoading}
          show={showSageNearbySalespersonModal}
          closeNearbySalesModal={() => {
            setShowSageNearbySalespersonModal(false);
          }}
          nearbySalesPersonData={nearbySalesPersonData}
          setNearbySalesPersonData={setNearbySalesPersonData}
          location={{
            lat: marker?.latitude,
            lng: marker?.longitude,
          }}
          address={{
            address: marker?.address,
            city: marker?.city,
            state: marker?.state,
            zipcode: marker?.zipcode,
          }}
          salesFilter={salesFilter}
          setSalesFilter={setSalesFilter}
        />
      </div>
    </InfoWindowF>
  );
  // )}
  // </MarkerF>
};
