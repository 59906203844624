import { combineReducers } from "redux";

// Front
import LayoutReducer from "reduxStore/slices/layouts/reducer";

const rootReducer = combineReducers({
  Layout: LayoutReducer,
});

export default rootReducer;
