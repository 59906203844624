import PasswordField from "components/common/fields/PasswordField";
import { ErrorMessage, Formik } from "formik";
import { showErrorMsg, showSuccessMsg } from "modules/helper";
import { userChangePasswordValidation } from "modules/validation";
import React from "react";
import { Button, Modal, Spinner, Form } from "react-bootstrap";
import { useUpdateUserMutation } from "reduxStore/rtk/EndPoints/UserEndPoint/UserSlice";

const UserChangePassword = ({ isShow, handleClose, id, setId }) => {
    return (
        <React.Fragment>
            <Modal
                centered
                show={isShow}
                onHide={handleClose}
                style={{ display: "block" }}
                tabIndex={-1}
            >
                <div className="modal-content border border-0">
                    <Modal.Header className="p-4 pb-0">
                        <Modal.Title as="h5">Change User Password</Modal.Title>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={handleClose}
                        ></button>
                    </Modal.Header>
                    <Modal.Body className="p-4">
                        <ChangePassword id={id} setId={setId} handleClose={handleClose} />
                    </Modal.Body>
                </div>
            </Modal>
        </React.Fragment>
    );
};

export default UserChangePassword;

export const ChangePassword = ({ id, setId, handleClose }) => {
    document.title = "Change User Password | User";
    const [updateUser, { isLoading }] = useUpdateUserMutation();
    const initialValues = {
        newPassword: "",
        confirmPassword: "",
    };
    const handleSubmit = async (values) => {
        let response = await updateUser({
            url: `/users/${id}`, data: {
                password: values?.newPassword
            }
        });
        if (response?.data?.type === "success") {
            showSuccessMsg(response?.data?.message);
            setId("");
            handleClose();
        } else {
            showErrorMsg(response?.error?.data?.message);
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={userChangePasswordValidation()}
            onSubmit={handleSubmit}
        >
            {({
                errors,
                values,
                handleSubmit,
                handleChange,
                handleBlur,
            }) => (
                <Form
                    id="form1"
                    name="form1"
                    action="#"
                    className="mt-4"
                    onSubmit={handleSubmit}
                    onKeyDown={(e) => {
                        e.key === "Enter" && e.preventDefault();
                    }}
                >
                    <PasswordField
                        label="New Password"
                        name="newPassword"
                        value={values?.newPassword}
                        placeholder="Enter Your New Password"
                        handleBlur={handleBlur}
                        k
                        handleChange={handleChange}
                        validationMsg={<ErrorMessage name="newPassword" />}
                    />
                    <PasswordField
                        label="Confirm Password"
                        name="confirmPassword"
                        value={values?.confirmPassword}
                        placeholder="Enter Your Confirm Password"
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        validationMsg={<ErrorMessage name="confirmPassword" />}
                    />
                    <div className="mt-3 text-end">
                        <Button type="submit" className="btn btn-success">
                            {isLoading && <Spinner size="sm" />}
                            Update
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    );
};
