
const authConfig = (method, url, data) => {
  return {
    headers: {
      "Content-Type": "application/json",

      Authorization:
        localStorage.getItem("accessToken") &&
        `Bearer ${localStorage.getItem("accessToken")}`,
    },
    method: method,
    url: `${url}`,
    body: data,
  };
};
const uploadConfig = (method, url, data) => {
  return {
    headers: {
      Authorization:
        localStorage.getItem("accessToken") &&
        `Bearer ${localStorage.getItem("accessToken")}`,
    },
    method: method,
    url: `${url}`,
    body: data,
  };
};

const query = {
  get: async (url, params) => {
    let response;
    let paramKeys = [];

    if (params) {
      Object.keys(params).map((key) => {
        params[key] && paramKeys.push(key + "=" + params[key]);
        return paramKeys;
      });
      const queryString =
        paramKeys && paramKeys.length ? paramKeys.join("&") : "";
      response = `${url}?${queryString}`;
    } else {
      response = `${url}`;
    }
    return response;
  },
};
export { uploadConfig, authConfig, query };
