import PageTitle from "components/common/PageTitle";
import { Button, Card, Col, Row } from "react-bootstrap";
import { NotificationList } from "./List";
import NotificationForm from "./Form";
import { useState } from "react";

const Notification = () => {
    const [addNotificationModal, setAddNotificationModal] = useState(false); // show/hide modal to add notification
    /** Notification Filter
     * this filter state variable is passed as prop in Notification list , which render notification table
     * Inside Notification List filter is passed as payload in rtk to set header params
     */
    const [notificationFilter, setNotificationFilter] = useState({
        url: "/notifications",
        name: "",
        sortBy: {},
        limit: 10,
        page: 1
    });
    return (
        <>
            <PageTitle title="Notifications" />
            <Card>
                <Card.Header>
                    <Row>
                        <Col xl={6}>
                            <Button onClick={() => setAddNotificationModal(!addNotificationModal)}>
                                Create Notification <i className="las la-plus"></i>
                            </Button>
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body>
                    {/* display notification list table */}
                    <NotificationList notificationFilter={notificationFilter} setNotificationFilter={setNotificationFilter} />
                </Card.Body>
            </Card >
            <NotificationForm
                modalTitle="Add Notification"
                isShow={addNotificationModal}
                handleClose={() => setAddNotificationModal(false)}
            />
        </>
    );
};

export default Notification;
