import { changeHTMLAttribute } from "reduxStore/slices/layouts/utils";
import {
  changeLayoutAction,
  changeLayoutModeAction,
  changeSidebarThemeAction,
  changeLayoutWidthAction,
  changeLayoutPositionAction,
  changeTopbarThemeAction,
  changeLeftsidebarSizeTypeAction,
  changeLeftsidebarViewTypeAction,
} from "reduxStore/slices/layouts/reducer";

/**
 * Changes the layout type
 * @param {*} param0
 */
export const changeLayout = (layout) => async (dispatch) => {
  try {
    if (layout === "horizontal") {
      document.documentElement.removeAttribute("data-sidebar-size");
    }
    changeHTMLAttribute("data-layout", layout);
    dispatch(changeLayoutAction(layout));
  } catch (error) {
    return error;
  }
};

/**
 * Changes the layout mode
 * @param {*} param0
 */
export const changeLayoutMode = (layoutMode) => async (dispatch) => {
  try {
    switch (layoutMode) {
      case "light":
        changeHTMLAttribute("data-bs-theme", "light");
        break;
      case "dark":
        changeHTMLAttribute("data-bs-theme", "dark");
        break;
      default:
        changeHTMLAttribute("data-bs-theme", "light");
        break;
    }
    dispatch(changeLayoutModeAction(layoutMode));
    dispatch(changeTopbarThemeAction(layoutMode));
  } catch (error) {
    // Handle any errors if needed
    return error;
  }
};

/**
 * Changes the left sidebar theme
 * @param {*} param0
 */
export const changeSidebarTheme = (sidebarTheme) => async (dispatch) => {
  try {
    changeHTMLAttribute("data-sidebar", sidebarTheme);
    dispatch(changeSidebarThemeAction(sidebarTheme));
  } catch (error) {
    return error;
  }
};

/**
 * Changes the layout width
 * @param {*} param0
 */
export const changeLayoutWidth = (layoutWidth) => async (dispatch) => {
  try {
    changeHTMLAttribute("data-layout-width", layoutWidth);
    // if (layoutWidth === 'lg') {
    //     changeHTMLAttribute("data-layout-width", "fluid");
    // } else {
    //     changeHTMLAttribute("data-layout-width", "boxed");
    // }
    dispatch(changeLayoutWidthAction(layoutWidth));
  } catch (error) {
    return error;
  }
};

/**
 * Changes the layout position
 * @param {*} param0
 */
export const changeLayoutPosition = (layoutposition) => async (dispatch) => {
  try {
    changeHTMLAttribute("data-layout-position", layoutposition);
    dispatch(changeLayoutPositionAction(layoutposition));
  } catch (error) {
    return error;
  }
};

/**
 * Changes the topbar themes
 * @param {*} param0
 */
export const changeTopbarTheme = (topbarTheme) => async (dispatch) => {
  try {
    changeHTMLAttribute("data-topbar", topbarTheme);
    dispatch(changeTopbarThemeAction(topbarTheme));
  } catch (error) {
    return error;
  }
};

/**
 * Changes the left sidebar size
 * @param {*} param0
 */
export const changeLeftsidebarSizeType =
  (leftsidebarSizetype) => async (dispatch) => {
    try {
      switch (leftsidebarSizetype) {
        case "lg":
          changeHTMLAttribute("data-sidebar-size", "lg");
          break;
        case "md":
          changeHTMLAttribute("data-sidebar-size", "md");
          break;
        case "sm":
          changeHTMLAttribute("data-sidebar-size", "sm");
          break;
        case "sm-hover":
          changeHTMLAttribute("data-sidebar-size", "sm-hover");
          break;
        default:
          changeHTMLAttribute("data-sidebar-size", "lg");
      }
      dispatch(changeLeftsidebarSizeTypeAction(leftsidebarSizetype));
    } catch (error) {
      return error;
    }
  };

/**
 * Changes the left sidebar views
 * @param {*} param0
 */
export const changeLeftsidebarViewType =
  (leftsidebarViewtype) => async (dispatch) => {
    try {
      changeHTMLAttribute("data-layout-style", leftsidebarViewtype);
      dispatch(changeLeftsidebarViewTypeAction(leftsidebarViewtype));
    } catch (error) {
      return error;
    }
  };
