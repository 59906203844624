import { authConfig, uploadConfig } from "reduxStore/rtk/Query/Config";
import { api } from "reduxStore/Service";

const userProfileApi = api.injectEndpoints({
    tagTypes: ["UserProfile"],
    endpoints: (builder) =>( {
        userProfile: builder.query({
            query: () => ({
              url: "/users/profile",
              method: "GET",
            }),
            providesTags: ["UserProfile"],
      
          }),
          changePassword: builder.mutation({
            query: (payload) => {
              return authConfig("POST", `users/change-password`, payload?.data);
            },
            transformResponse: (response) => {
              return response;
            },
            invalidatesTags: ["UserProfile"],
          }),
          uploadProfile: builder.mutation({
            query: (payload) => {
              return uploadConfig("POST", `users/profile`, payload);
            },
            transformResponse: (response) => {
              return response;
            },
            invalidatesTags: ["UserProfile"],
          }),
    })
})

export const {
    useUserProfileQuery,
    useUploadProfileMutation,
    useChangePasswordMutation,
}=userProfileApi