import { Form } from "react-bootstrap";
import { themes } from "./mapTheme";
const MapThemes = ({ mapThemes, setMapThemes }) => {
  const handleTheme = (name = "", theme = "") => {
    localStorage.setItem("MAP_THEME", JSON.stringify({ name, theme }));
    setMapThemes({ name, theme });
  };
  return (
    <div className="py-2">
      {themes?.map((theme) => (
        <div
          key={theme.id}
          className="d-flex  mb-2 justify-content-between align-items-center"
        >
          <div className="d-flex form-check">
            <Form.Check
              checked={mapThemes.name === theme.name}
              type="radio"
              name="map-styles"
              id={theme.id}
              className="px-2"
              onClick={() => handleTheme(theme.name, theme.theme)}
              onChange={() => {}}
            />
            <Form.Label htmlFor={theme.id}>{theme.name}</Form.Label>
          </div>
          <img
            onClick={() => handleTheme(theme.name, theme.theme)}
            style={{ cursor: "pointer" }}
            src={theme.themeImage}
            className="flex-shrink-0 me-3 avatar-md rounded"
            alt="..."
          />
        </div>
      ))}
    </div>
  );
};

export default MapThemes;
