import React, { createContext, useContext, useEffect, useState } from "react";
import { useUserProfileQuery } from "reduxStore/rtk/EndPoints/LoginEndPoint/userProfileSlice";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const { data, isSuccess } = useUserProfileQuery();
  const [user, setUser] = useState(null);
  useEffect(() => {
    setUser(data?.data);
  }, [data]);
  const [isFullScreenMode, setIsFullScreenMode] = useState(true);
  return (
    <AuthContext.Provider
      value={{ user, setUser, isFullScreenMode, setIsFullScreenMode }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
