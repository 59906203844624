import { authConfig } from "reduxStore/rtk/Query/Config";
import { api } from "reduxStore/Service";

// authenticaton api and package api
const notificationApi = api.injectEndpoints({
    tagTypes: ["notification"],
    endpoints: (builder) => ({
        getNotification: builder.query({
            query: (payload) => {
                let url = payload?.url;
                if (payload?.name || payload?.isActive || payload?.sortBy?.name || payload?.sortBy?.email || payload?.page) {
                    url = `${payload?.url
                        }${payload?.limit ?
                            `?limit=${payload?.limit}` : ""
                        }${payload?.page && `&page=${payload?.page}`}`;
                    return authConfig("GET", url);
                }
                return authConfig("GET", url);
            },
            transformResponse: (response) => {
                return response;
            },
            providesTags: ["notification"],
        }),
        getNotificationById: builder.mutation({
            query: (payload) => {
                return authConfig("GET", `${payload?.url}`,);
            },
            transformResponse: (response) => {
                return response?.data;
            },
            providesTags: ["notification"],
        }),
        createNotification: builder.mutation({
            query: (payload) => {
                return authConfig("POST", `${payload?.url}`, payload?.data);
            },
            transformResponse: (response) => {
                return response;
            },
            invalidatesTags: ["notification"],
        }),
        updateNotification: builder.mutation({
            query: (payload) => {
                return authConfig("PUT", `${payload?.url}`, payload?.data);
            },
            transformResponse: (response) => {
                return response;
            },
            invalidatesTags: ["notification"],
        }),
        deleteNotification: builder.mutation({
            query: (payload) => {
                return authConfig("DELETE", `${payload?.url}`);
            },
            invalidatesTags: ["notification"],
        }),
        pauseNotification: builder.mutation({
            query: (payload) => {
                return authConfig("PUT", `/notifications/paused-notification/${payload}`);
            },
            invalidatesTags: ["notification"],
        }),
    }),
});
export const {
    useLazyGetNotificationQuery,
    useCreateNotificationMutation,
    useGetNotificationByIdMutation,
    useDeleteNotificationMutation,
    usePauseNotificationMutation,
    useUpdateNotificationMutation
} = notificationApi;
