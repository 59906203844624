import { useEffect, useMemo, useState } from "react";
import {
  useDownloadNotificationFilesMutation,
  useLazyGetNotificationFilesQuery,
} from "reduxStore/rtk/EndPoints/SageEndPoint/sageSlice";
import { ReactTable } from "../tables/ReactTable";
import PageTitle from "components/common/PageTitle";
import { Card, Col, Row, Spinner } from "react-bootstrap";
import { convertToExcel, dateTimeFormat } from "modules/helper";
import Tooltips from "components/common/tooltipBox/Tooltip";
import { useAuth } from "components/context/UserContext";

const NotificationReports = () => {
  const { user } = useAuth();

  const [getNotificatoinFiles, { data: notificationFiles, isSuccess }] =
    useLazyGetNotificationFilesQuery(); // get Notification files to show in table

  /**Notification filter used to set header params while calling api */
  const [notificationFilesFilter, setNotificationFilesFilter] = useState({
    url: "sage/files/",
    name: "",
    sortBy: {},
    limit: 10,
    page: 1,
  });
  const [
    downloadNotificationFiles,
    { isLoading: downloadingNotificationFiles },
  ] = useDownloadNotificationFilesMutation();
  useEffect(() => {
    getNotificatoinFiles(notificationFilesFilter);
    // eslint-disable-next-line
  }, [notificationFilesFilter]);

  const renderActionButton = (
    tooltipText,
    onClickAction,
    iconName,
    buttonColor
  ) => (
    <Tooltips msg={tooltipText} direction="bottom">
      <button
        disabled={downloadingNotificationFiles}
        className={`btn btn-soft-${buttonColor} btn-sm d-inline-block`}
        type="button"
        onClick={() => {
          onClickAction();
        }}
      >
        {downloadingNotificationFiles && <Spinner size="sm" />}
        <i className={`${iconName}  align-middle ri-lg`}></i>
      </button>
    </Tooltips>
  );
  // function to download user notified by notification excel files
  const handleFileDownload = async (data) => {
    let response = await downloadNotificationFiles({
      url: "sage/file-download",
      data: {
        fileName: data?.name,
      },
    });
    if (response?.data?.type === "success") {
      convertToExcel(response?.data?.data, "notification");
    }
  };

  // columns to be displayed in report table
  const columns = useMemo(() => {
    // Base columns that are always included
    const baseColumns = [
      {
        Header: "Name",
        accessor: "name",
        Filter: false,
        isSortable: false,
        Cell: (cell) => {
          return (
            <div className="d-flex gap-1">
              <div className="text-body align-middle ">
                {cell.row.original.notification?.name ?? "Alert"}
              </div>
            </div>
          );
        },
      },
      {
        Header: "Hail Size",
        accessor: "hailSize",
        Filter: false,
        isSortable: false,
        Cell: (cell) => {
          return (
            <div className="d-flex gap-1">
              <div className="text-body align-middle">
                {cell.row.original.hailSize ? (
                  <>
                    <span className="badge bg-light text-uppercase text-dark">
                      <i className="ri-thunderstorms-fill mx-1 text-info"></i>
                      {cell.row.original.hailSize}
                    </span>
                  </>
                ) : (
                  <div className="fs-5 ms-4"> -</div>
                )}
              </div>
            </div>
          );
        },
      },
      {
        Header: "Email",
        accessor: "email",
        Filter: false,
        isSortable: false,
        Cell: (cell) => {
          return (
            <div className="d-flex gap-1">
              <div className="text-body align-middle">
                {cell.row.original.notification?.userId?.email ?? ""}
              </div>
            </div>
          );
        },
      },
      {
        Header: "Created On",
        accessor: "createdAt",
        Filter: false,
        isSortable: true,
        Cell: (cell) => {
          return (
            <div className="d-flex gap-1">
              <div className="text-body align-middle">
                {dateTimeFormat(cell.row.original.createdAt)}
              </div>
            </div>
          );
        },
      },
    ];

    // Conditionally add "Action" column based on user permissions
    if (!user?.salesId || (user?.salesId && user?.allowExport)) {
      baseColumns.push({
        Header: "Action",
        accessor: "action",
        Filter: false,
        isSortable: false,
        Cell: (cell) => (
          <div>
            <ul className="list-inline hstack m-0">
              <li className="list-inline-item">
                {renderActionButton(
                  `Download File ${cell.row.original?.name}`,
                  () => handleFileDownload(cell.row.original),
                  "ri-file-download-line",
                  "primary"
                )}
              </li>
            </ul>
          </div>
        ),
      });
    }

    return baseColumns;
  }, [user]);
  return (
    <>
      <PageTitle title="Reports " />
      <Card>
        <Card.Header>
          <Row>
            <Col xl={11}>
              <h5 className="text-dark fw-bold align-items-center">Reports</h5>
            </Col>

            <Col xl={1} className="px-0">
              <div className="">
                <select
                  className="form-select"
                  value={notificationFilesFilter?.limit}
                  onChange={(e) =>
                    setNotificationFilesFilter({
                      ...notificationFilesFilter,
                      limit: e.target.value,
                    })
                  }
                >
                  {[10, 20, 30, 40, 50].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      Show {pageSize}
                    </option>
                  ))}
                </select>
              </div>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <ReactTable
            data={notificationFiles?.data?.fileData ?? []}
            isSuccess={!isSuccess} // manage loading
            columns={columns}
            pageInfo={{
              totalPages: notificationFiles?.data?.totalPages,
              currentPage: notificationFiles?.data?.page,
              totalCount: notificationFiles?.data?.totalResults,
              limit:
                notificationFiles?.data?.limit >
                notificationFiles?.data?.totalResults
                  ? notificationFiles?.data?.totalResults
                  : notificationFiles?.data?.limit,
            }}
            filter={notificationFilesFilter}
            setFilter={setNotificationFilesFilter}
            tableHeight="74vh"
          />
        </Card.Body>
      </Card>
    </>
  );
};

export default NotificationReports;
