import TableContainer from "components/common/Tabledata/TableContainer";
import Tooltips from "components/common/tooltipBox/Tooltip";
import { useAuth } from "components/context/UserContext";
import { dateFormat } from "modules/helper";
import React, { useEffect, useMemo, useState } from "react";
import { Button, Card, Spinner } from "react-bootstrap";
import * as XLSX from "xlsx";

const WeatherHistoryInfoWindow = ({
  weatherData,
  setWeatherData,
  location,
  tableHeight = "auto",
  weatherFilters = "",
  setWeatherFilters = () => {},
}) => {
  const { user } = useAuth();
  const [tableData, setTableData] = useState(weatherData);
  const [filterData, setFilterData] = useState({
    limit: 10000,
    page: 1,
    sortBy: {},
  });
  const systemTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const itemsPerPage = 10;
  const [downloadingExcel, setDownloadingExcel] = useState(false);

  useEffect(() => {
    if (weatherData) {
      const sortedData = [...weatherData]; // store weather data in variable to prevent mutation
      if (filterData.sortBy.date) {
        sortedData.sort((a, b) => {
          const dateA = new Date(Number(a.date));
          const dateB = new Date(Number(b.date));
          return filterData.sortBy.date === "asc"
            ? dateA - dateB
            : dateB - dateA;
        });
        setTableData(sortedData); // storing the whole sorted data in excelData state
      } else {
        setTableData(weatherData);
      }
      // slice data from sorted data to store in table
    }
  }, [filterData]);
  const getTotalPages = (data, itemsPerPage) =>
    Math.ceil((data?.length ?? 0) / itemsPerPage);
  const columns = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "date",
        Filter: false,
        isSortable: true,
        Cell: ({ row }) => {
          return (
            <div className="d-flex gap-1">
              <div className="text-body d-flex align-items-start mx-2">
                <div
                  className="position-relative  py-1 text-primary changeable-date"
                  onClick={() => {
                    setWeatherFilters({
                      ...weatherFilters,
                      date: new Date(Number(row?.original?.date)),
                    });
                  }}
                >
                  {dateFormat(Number(row?.original?.date))}
                </div>
              </div>
            </div>
          );
        },
      },
      {
        Header: "0 Miles",
        accessor: "0",
        Filter: false,
        isSortable: false,
        Cell: ({ row }) => {
          return (
            <div className=" py-1 text-dark">{row?.original[0] ?? "-"}</div>
          );
        },
      },
      {
        Header: "1 Miles",
        accessor: "1",
        Filter: false,
        isSortable: false,
        Cell: ({ row }) => {
          return (
            <div className=" py-1 text-dark">{row?.original[1] ?? "-"}</div>
          );
        },
      },
      {
        Header: "3 Miles",
        accessor: "3",
        Filter: false,
        isSortable: false,
        Cell: ({ row }) => {
          return (
            <div className=" py-1 text-dark">{row?.original[3] ?? "-"}</div>
          );
        },
      },
      {
        Header: "10 Miles",
        accessor: "10",
        Filter: false,
        isSortable: false,
        Cell: ({ row }) => {
          return (
            <div className=" py-1 text-dark">{row?.original[10] ?? "-"}</div>
          );
        },
      },
    ],
    [systemTimeZone]
  );
  const handleDownload = async () => {
    setDownloadingExcel(true);
    try {
      const formattedData = formatDatesInData(tableData);
      const worksheet = XLSX.utils.json_to_sheet(formattedData, {
        header: Object.keys(formattedData[0]),
        dateNF: "yyyy-mm-dd",
      });
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      const formattedName = location
        .replace(/[^a-zA-Z0-9\s]/g, "") // Remove special characters
        .replace(/\s+/g, "-")
        .toLowerCase(); // Replace spaces with hyphens

      const fileName = `${location ? `${formattedName}-weather-history` : `weather-history`}-${new Date().getTime()}.xlsx`;
      await XLSX.writeFile(workbook, fileName);
    } catch (error) {
      console.error("Error exporting data:", error);
    } finally {
      setDownloadingExcel(false);
    }
  };
  const formatDatesInData = (data) => {
    if (!data.length) return [];
    const headers = ["Date", "0", "1", "3", "10"];
    return data.map((row) => {
      return headers.reduce((acc, header) => {
        if (
          header.toLocaleLowerCase() === "date" &&
          row[header.toLocaleLowerCase()]
        ) {
          acc[header] = dateFormat(Number(row[header.toLocaleLowerCase()]));
        } else {
          const newHeader = `${header} miles`;
          acc[newHeader] = row[header] ?? "-";
        }
        return acc;
      }, {});
    });
  };
  return (
    <>
      <Card className="m-0 p-0">
        <Card.Body>
          <TableContainer
            isPagination={false}
            //   customPageSize={1}
            columns={columns}
            setFilter={setFilterData}
            filter={filterData}
            pageInfo={{
              totalPages: getTotalPages(tableData, itemsPerPage),
              currentPage: filterData.page,
              totalCount: tableData.length,
              limit: 10 > tableData.length ? tableData.length : 10,
            }}
            data={tableData}
            customPageSize={10}
            divClassName="table-card table-responsive text-start  overflow-hidden "
            tableClass="table-hover table-nowrap align-middle mb-0 border table "
            isBordered={false}
            PaginationClass="align-items-center"
            tableHeight={tableHeight}
          />
        </Card.Body>
      </Card>
      {tableData?.length > 0 &&
        (!user?.salesId || (user?.salesId && user?.allowExport)) && (
          <div className="text-end mt-3">
            <Tooltips msg={"Download Data"}>
              <Button
                disabled={downloadingExcel}
                onClick={handleDownload}
                className="btn btn-success"
              >
                {downloadingExcel ? (
                  <Spinner size="sm" />
                ) : (
                  <i className="bi bi-file-earmark-excel-fill fs-6"></i>
                )}
              </Button>
            </Tooltips>
          </div>
        )}
    </>
  );
};

export default WeatherHistoryInfoWindow;
