// import { PulseLoader } from "react-spinners";
import { Spinner } from "react-bootstrap";

export default function ProcessButton({
  label = "Enter",
  type = "submit",
  handleClick = () => {},
  className = "",
  isLoading = false,
  isDisable = false,
}) {
  return (
    <button
      type={type}
      onClick={handleClick}
      className={` ${className ? className : `btn btn-primary w-100`}`}
      disabled={isLoading || isDisable}
    >
      {isLoading ? (
        <span className="h-[20px] flex justify-center items-center">
          <Spinner size="sm" />
        </span>
      ) : (
        <span className="h-[20px] flex justify-center items-center">
          {label}
        </span>
      )}
    </button>
  );
}
