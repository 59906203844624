import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { showErrorMsg, showSuccessMsg } from "modules/helper";
import { getAuthToken, removeAuthToken, setAuthToken } from "modules/util";
import {
  logOut,
  serverError,
  setCredentials,
  setUserProfile,
} from "./rtk/EndPoints/LoginEndPoint/authSlice";
const baseQueryUrl = (baseUrl) => {
  const baseQuery = fetchBaseQuery({
    baseUrl: baseUrl,
    withCredentials: true,
    credentials: "same-origin",
    prepareHeaders: (headers, { getState, endpoint }) => {
      const token = getState().auth.token;
      if (token && endpoint !== "refresh") {
        headers.set("authorization", `Bearer ${token}`);
      }
      // Check if the request body is FormData
      if (endpoint.body instanceof FormData) {
        // Set the Content-Type header to multipart/form-data
        headers.set("Content-Type", "multipart/form-data");
      }
      return headers;
    },
  });
  return async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);
    if (result?.error) {
      if (
        [401].includes(result?.error?.status) &&
        !extraOptions?.customRefresh &&
        !!getAuthToken()
      ) {
        // Send refresh token to get a new access token
        const refreshToken = getAuthToken() || "";
        // Call refresh API
        const refreshResult = await fetch(
          `${process.env.REACT_APP_AUTH_SERVICE}auth/refresh-tokens`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ refreshToken: refreshToken }),
          },
        );
        const data = await refreshResult.json();
        if (data.type === "success") {
          setAuthToken(data?.data?.refresh?.token);
          api.dispatch(setCredentials(data?.data?.access));
          const userResult = await fetch(
            `${process.env.REACT_APP_AUTH_SERVICE}users/profile`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${data?.data?.access?.token}`,
              },
            },
          );
          const userData = await userResult.json();
          if (userData.type === "success") {
            api.dispatch(setUserProfile(userData.data));
            result = await baseQuery(args, api, extraOptions);
          } else {
            api.dispatch(logOut());
            return;
          }
        } else {
          api.dispatch(logOut());
          removeAuthToken();
          return;
        }
      } else if (
        result?.error?.status === "FETCH_ERROR" ||
        result?.error?.code === 500 ||
        result?.error?.code === "500"
      ) {
        api.dispatch(serverError(true));
      }
      if (result?.error?.data?.message) {
        showErrorMsg(result?.error?.data?.message);
        if (result?.error?.data?.code === 401) {
          removeAuthToken()
        }
      }
      return result;
    } else {
      if (result?.data?.status === "ok") {
        showSuccessMsg(result?.data?.message);
      } else if (result?.data?.status === "error") {
        showErrorMsg(result?.data?.message);
      }
      return result;
    }
  };
};

export const api = createApi({
  reducerPath: "api",
  baseQuery: baseQueryUrl(`${process.env.REACT_APP_AUTH_SERVICE}`),
  endpoints: () => ({}),
});

const WeatherBaseQueryUrl = (baseUrl) => {
  const baseQuery = fetchBaseQuery({
    baseUrl: baseUrl,
    withCredentials: true,
    credentials: "same-origin",
    prepareHeaders: (headers, { getState, endpoint }) => {
      const token = getState().auth.token;
      if (token && endpoint !== "refresh") {
        headers.set("authorization", `Bearer ${token}`);
      }
      // Check if the request body is FormData
      if (endpoint.body instanceof FormData) {
        // Set the Content-Type header to multipart/form-data
        headers.set("Content-Type", "multipart/form-data");
      }
      return headers;
    },
  });
  return async (args, api, extraOptions) => {
    const result = await baseQuery(args, api, extraOptions);

    if (result?.data?.status === "ok") {
      showSuccessMsg(result?.data?.message);
    } else if (result?.data?.status === "error") {
      showErrorMsg(result?.data?.message);
    }
    return result;
  };
};

export const weatherApi = createApi({
  reducerPath: "weatherApi",
  baseQuery: WeatherBaseQueryUrl(`${process.env.REACT_APP_WEATHER_SERVICE}`),
  endpoints: () => ({}),
});

export const sage = createApi({
  reducerPath: "sage",
  baseQuery: baseQueryUrl(`${process.env.REACT_APP_SAGE_SERVICE}`),
  endpoints: () => ({}),
});
