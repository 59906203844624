import withRouter from "components/common/withRouter";
import { AuthContext } from "components/context/UserContext";
import Header from "components/layout/Header";
import Sidebar from "components/layout/Sidebar";
import React, { useContext, useEffect } from "react";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  changeLayout,
  changeLayoutMode,
  changeLayoutPosition,
  changeLayoutWidth,
  changeLeftsidebarSizeType,
  changeLeftsidebarViewType,
  changeSidebarTheme,
  changeTopbarTheme,
} from "reduxStore/slices/thunk";
import { createSelector } from "reselect";

const Layout = (props) => {
  const dispatch = useDispatch();
  const { isFullScreenMode } = useContext(AuthContext);
  const selectLayoutState = (state) => state.rootReducer.Layout;

  const selectLayoutProperties = createSelector(
    selectLayoutState,
    (layout) => ({
      layoutType: layout?.layoutType,
      layoutThemeType: layout?.layoutThemeType,
      leftSidebarType: layout?.leftSidebarType,
      layoutModeType: layout?.layoutModeType,
      layoutWidthType: layout?.layoutWidthType,
      layoutPositionType: layout?.layoutPositionType,
      topbarThemeType: layout?.topbarThemeType,
      leftsidbarSizeType: layout?.leftsidbarSizeType,
      leftSidebarViewType: layout?.leftSidebarViewType,
    })
  );
  const {
    layoutType,
    leftSidebarType,
    layoutModeType,
    layoutWidthType,
    layoutPositionType,
    topbarThemeType,
    leftsidbarSizeType,
    leftSidebarViewType,
  } = useSelector(selectLayoutProperties);

  /*
    layout settings
    */
  useEffect(() => {
    if (
      layoutType ||
      leftSidebarType ||
      layoutModeType ||
      layoutWidthType ||
      layoutPositionType ||
      topbarThemeType ||
      leftsidbarSizeType ||
      leftSidebarViewType
    ) {
      window.dispatchEvent(new Event("resize"));
      dispatch(changeLeftsidebarViewType(leftSidebarViewType));
      dispatch(changeLeftsidebarSizeType(leftsidbarSizeType));
      dispatch(changeSidebarTheme(leftSidebarType));
      dispatch(changeLayoutMode(layoutModeType));
      dispatch(changeLayoutWidth(layoutWidthType));
      dispatch(changeLayoutPosition(layoutPositionType));
      dispatch(changeTopbarTheme(topbarThemeType));
      dispatch(changeLayout(layoutType));
    }
  }, [
    layoutType,
    leftSidebarType,
    layoutModeType,
    layoutWidthType,
    layoutPositionType,
    topbarThemeType,
    leftsidbarSizeType,
    leftSidebarViewType,
    dispatch,
  ]);

  const onChangeLayoutMode = (value) => {
    if (changeLayoutMode) {
      dispatch(changeLayoutMode(value));
    }
  };
  const styleObj = { paddingTop: "5rem", paddingLeft: "0", paddingRight: "0" };
  return (
    <React.Fragment>
      <div id="layout-wrapper">
        <Sidebar layoutType={layoutType} />
        <Header
          layoutModeType={layoutModeType}
          onChangeLayoutMode={onChangeLayoutMode}
        />
        <div className={`main-content ${!isFullScreenMode && " ms-0"}`}>
          <div
            className="page-content"
            style={!isFullScreenMode ? styleObj : {}}
          >
            <Container fluid>{props.children}</Container>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Layout);
