import { OverlayTrigger, Tooltip } from "react-bootstrap";

const Tooltips = ({ children, msg, direction }) => {
  return (
    <OverlayTrigger
      trigger={["click", "hover"]}
      placement={direction ?? "bottom"}
      overlay={<Tooltip id="tooltip">{msg}</Tooltip>}
      rootClose={true}
    >
      {children}
    </OverlayTrigger>
  );
};

export default Tooltips;
