import Slider from "rc-slider";
import React from "react";

const RangeSlider = ({
  range,
  setRange,
  disabled = false,
  setSalesFilter,
  salesFilter,
}) => {
  const handleChange = (value) => {
    const newRange = [...value];
    const currentGap = newRange[1] - newRange[0];
    // Only update if the gap is valid
    if (currentGap >= 0.25) {
      setRange(newRange);
    } else {
      return;
    }
  };
  return (
    <div className="m-2" style={{ width: "200px" }}>
      <div className="d-flex my-2 justify-content-between">
        <div>Miles</div>
        <div>{`${range[0].toFixed(2)} - ${range[1].toFixed(2)}`}</div>
      </div>
      <Slider
        min={0} 
        max={10} 
        value={range}
        step={0.25}
        range={true}
        disabled={disabled}
        className="text-primary"
        allowCross={false}
        onChange={handleChange}
        onChangeComplete={(nextValue) => {
          setSalesFilter({
            ...salesFilter,
            minMiles: nextValue[0],
            maxMiles: nextValue[1],
          });
        }}
        styles={{
          track: {
            background: "#00529D",
          },
        }}
      />
    </div>
  );
};

export default RangeSlider;
