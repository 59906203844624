import { ErrorMessage, Formik } from "formik";
import { showErrorMsg, showSuccessMsg } from "modules/helper";
import { changePasswordValidation } from "modules/validation";
import React from "react";
import { Button, Modal, Spinner, Form } from "react-bootstrap";
import { useChangePasswordMutation } from "reduxStore/rtk/EndPoints/LoginEndPoint/userProfileSlice";
import PasswordField from "./fields/PasswordField";
import { useLogoutMutation } from "reduxStore/rtk/EndPoints/LoginEndPoint/LoginSlice";
import { getAuthToken } from "modules/util";
import { logOut, selectCurrentToken } from "reduxStore/rtk/EndPoints/LoginEndPoint/authSlice";
import { useDispatch, useSelector } from "react-redux";

const ChangePasswordModal = ({ isShow, handleClose }) => {
  return (
    <React.Fragment>
      <Modal
        centered
        show={isShow}
        onHide={handleClose}
        style={{ display: "block" }}
        tabIndex={-1}
      >
        <div className="modal-content border border-0">
          <Modal.Header className="p-4 pb-0">
            <Modal.Title as="h5">Change Password</Modal.Title>
            <button
              type="button"
              className="btn-close"
              onClick={handleClose}
            ></button>
          </Modal.Header>
          <Modal.Body className="p-4">
            <ChangePassword />
          </Modal.Body>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default ChangePasswordModal;

export const ChangePassword = () => {
  document.title = "Change User Password | Dashboard";
  const [changePassword, { isLoading }] = useChangePasswordMutation();
  const [logoutMutation] = useLogoutMutation();
  const token = useSelector(selectCurrentToken);
  const dispatch = useDispatch();
  const initialValues = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  const handleSubmit = async (values) => {
    let response = await changePassword({ data: values });
    if (response?.data?.type === "success") {
      showSuccessMsg(response?.data?.message);
      logoutMutation({
        refreshToken: getAuthToken() ? getAuthToken() : "",
        token: token,
      });
      dispatch(logOut({ selfLogout: true }));
      window.location.reload(); // after location storage removes the token and user role, need to reload/ navigate wont work here
    } else {
      showErrorMsg(response?.error?.data?.message);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={changePasswordValidation()}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        touched,
        values,
        handleSubmit,
        handleChange,
        handleBlur,
        setFieldValue,
        setFieldTouched,
      }) => (
        <Form
          id="form1"
          name="form1"
          action="#"
          className="mt-4"
          onSubmit={handleSubmit}
          onKeyDown={(e) => {
            e.key === "Enter" && e.preventDefault();
          }}
        >
          <PasswordField
            label="Old Password"
            name="oldPassword"
            value={values?.oldPassword}
            placeholder="Enter Your Old Password"
            handleBlur={handleBlur}
            handleChange={handleChange}
            validationMsg={<ErrorMessage name="oldPassword" />}
          />
          <PasswordField
            label="New Password"
            name="newPassword"
            value={values?.newPassword}
            placeholder="Enter Your New Password"
            handleBlur={handleBlur}
            k
            handleChange={handleChange}
            validationMsg={<ErrorMessage name="newPassword" />}
          />
          <PasswordField
            label="Confirm Password"
            name="confirmPassword"
            value={values?.confirmPassword}
            placeholder="Enter Your Confirm Password"
            handleBlur={handleBlur}
            handleChange={handleChange}
            validationMsg={<ErrorMessage name="confirmPassword" />}
          />
          <div className="mt-3 text-end">
            <Button type="submit" className="btn btn-success">
              {isLoading && <Spinner size="sm" />}
              Submit
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
