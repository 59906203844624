import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  MarkerF,
  InfoWindowF,
  Circle,
  Polygon,
} from "@react-google-maps/api";

import { PuffLoader } from "react-spinners";
import { useGetWeatherDataMutation } from "reduxStore/rtk/EndPoints/WeatherEndPoint/weatherSlice";
import {
  containerStyle,
  dateFormat,
  isValidArray,
  setBodyForCustomerApi,
} from "modules/helper";
import { Card } from "react-bootstrap";
import leads from "../../../../assets/images/leads.png";
import customer from "../../../../assets/images/customer.png";
import {
  formatDateCustomerFilter,
} from "components/common/CollumnFormator";
import UserDrawer from "components/common/Drawer/UserDrawer";
import Tooltips from "components/common/tooltipBox/Tooltip";
import { MarkerComponent } from "./MarkerComponent";
import { MapHeader } from "./MapHeader";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { getMapTheme } from "modules/util";
import { formatDate, getPolygonColor } from "./helper";
import DrawingManagerComponent from "./DrawingManagerComponent";
import SearchLocation from "./SearchLocation";
import InfoWindowHeader from "./InfoWindowHeader";
const libraries = ["places", "drawing", "geometry"];
const MapComponent = ({
  mapRef,
  searchedLocation,
  clickableSearchLocation = true,
  setSearchedLocation,
  mapFilters = {},
  setMapFilters,
  weatherFilters = {},
  setInvalidCoordinates,
  invalidCoordinates = [],
  hailSize,
  setHailSize,
  circleData,
  isFilterApplied,
  setIsFilterApplied,
  setCircleData,
  polygonData,
  setPolygonData,
  isHeader = false,
  mapStyle,
  headFunc = {},
  notification = {},
  currentPolygon,
  setCurrentPolygon,
  inputRef,
}) => {
  useEffect(() => {
    if (notification?.values?.area?.type === "circle") {
      setCircleData({
        center: {
          lat: notification?.values?.area?.coordinates[0]?.[0]?.[1],
          lng: notification?.values?.area?.coordinates[0]?.[0]?.[0],
        },
        radius: notification?.values?.area?.radius, // The radius in miles, you may need to convert it to meters (1 mile = 1609.34 meters)
      });
      setPolygonData([]);
    }

    if (notification?.values?.area?.type === "polygon") {
      let newData = notification?.values?.area?.coordinates?.[0].map(
        (coord) => ({
          lat: coord[1],
          lng: coord[0],
        })
      );
      setPolygonData(newData);
      setCircleData({});
    }
    // eslint-disable-next-line
  }, [notification?.values?.area]);

  const {
    handleSalesFilter,
    handleHailSize,
    isLeadDataLoading,
    leadsData,
    salesFilter,
    setSalesFilter,
    leadIsSuccess,
    showSalesModal,
    showWeatherHistoryModal,
    setShowWeatherHistoryModal,
    handleWeatherHistoryModal,
    weatherHistoryFilter,
    setWeatherHistoryFilter,
    setShowSalesModal,
    setCustomerFilter,
    getLeads,
    getCustomers,
    customerData,
    isCustomerDataLoading,
    isSuccess,
    isApiCalling,
    setLeadData,
    setIsApiCalling,
    toggleRightCanvas,
    sliderStyle,
    isRight,
    resetFilter = {},
    setWeatherFilters,
  } = headFunc;
  useEffect(() => {}, []);
  const [isCustomLoadding, setIsCustomLoadding] = useState(false);
  const [marker, setMarker] = useState(null);
  const [hailMarker, setHailMarker] = useState(null);
  const [showUserCanvas, setShowUserCanvas] = useState(false);
  const [mapThemes, setMapThemes] = useState(getMapTheme());
  const toggleUserCanvas = (showUserCanvas) => {
    setShowUserCanvas(!showUserCanvas);
  };

  const [
    getWeatherData,
    {
      data: weatherData,
      isLoading: isLoadingWeatherData,
      isSuccess: weatherDataSucess,
    },
  ] = useGetWeatherDataMutation(); // get weather Info
  const { date } = weatherFilters;

  const formattedDate = formatDate(date); // format the date in required format

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries,
  });
  const markersRef = useRef([]); // Reference to store markers
  const markerClustererRef = useRef(null); // Re
  const defaultCenter = {
    lat: 38.44259214791658,
    lng: -90.25809072734377,
  };
  // eslint-disable-next-line
  const [zoomLevel, setZoomLevel] = useState(5);
  const [center, setCenter] = useState(defaultCenter);

  const customerValidateCons =
    mapFilters?.user?.value === "customer" ||
    mapFilters?.user?.value === "all" ||
    mapFilters?.user?.values === "customer" ||
    mapFilters?.user?.values === "all";
  const leadsValidateCons =
    mapFilters?.user?.value === "leads" ||
    mapFilters?.user?.value === "all" ||
    mapFilters?.user?.values === "leads" ||
    mapFilters?.user?.values === "all";
  useEffect(() => {
    function styleFeature(feature) {
      const hailSize = feature.getProperty("HailSize");
      const color = getPolygonColor(hailSize);
      return {
        fillColor: color,
        strokeWeight: 0.7,
        fillOpacity: 0.7,
        strokeColor: color,
        strokeOpacity: 0.7,
      };
    }
    if (mapRef?.current) {
      let data = { date: formattedDate };
      if (hailSize > 0) {
        data = {
          date: formattedDate,
          HailSize: hailSize,
        };
      }
      if (!isNaN(data?.date?.split("/")[0])) {
        let res = getWeatherData({
          url: "weather/",
          data: data,
        });
        mapRef?.current?.data?.addGeoJson(res?.data?.data);
      }
      mapRef?.current?.data?.setStyle(styleFeature);
      onLoadMap();
      // mapRef?.current?.clearLayer();
    }

    // eslint-disable-next-line
  }, [formattedDate, hailSize]);
  useEffect(() => {
    let timeoutId;
    if (hailMarker) {
      timeoutId = setTimeout(() => {
        setHailMarker(null);
      }, 3000);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [hailMarker]);
  const clearAllMarkersAndCluster = () => {
    markersRef.current.forEach((marker) => {
      marker.setMap(null);
      marker.visible = false;
    });
    markersRef.current = [];
    if (markerClustererRef.current) {
      markerClustererRef.current.clearMarkers(); // Remove all markers from the cluster
      markerClustererRef.current = null; // Reset the clusterer ref
      // map?.removeOverlay(markerClustererRef.current);
    }
    // Ensure the map reference is updated if necessary
  };
  //  when map loads successfully execute this function to get weather data
  useMemo(async () => {
    let data = { date: formattedDate };
    if (hailSize > 0) {
      data = {
        date: formattedDate,
        HailSize: hailSize,
      };
    }
    if (!isNaN(data?.date?.split("/")[0])) {
      let res = getWeatherData({
        url: "weather/",
        data: data,
      });
      mapRef?.current?.data?.addGeoJson(res?.data?.data);
    }
    // eslint-disable-next-line
  }, []);
  const onLoadMap = useMemo(
    () => async (map) => {
      // function used to get color of the polygon
      function styleFeature(feature) {
        const hailSize = feature.getProperty("HailSize");
        const color = getPolygonColor(hailSize);
        return {
          fillColor: color,
          strokeWeight: 0.7,
          fillOpacity: 0.7,
          strokeColor: color,
          strokeOpacity: 0.7,
        };
      }
      if (map) {
        mapRef.current = map;
        mapRef.current.data.forEach((feature) => {
          mapRef.current.data.remove(feature);
        });
      } else if (mapRef?.current) {
        mapRef.current?.data?.forEach((feature) => {
          mapRef.current.data.remove(feature);
        });
      }
      mapRef?.current?.data.addListener("click", function (event) {
        setHailMarker({
          lat: event.latLng.lat(),
          lng: event.latLng.lng(),
          hailSize: event?.feature?.getProperty("HailSize") ?? null,
          dateTime: event?.feature?.getProperty("DateTime") ?? null,
          Date: event?.feature?.getProperty("Date") ?? null,
        });
      });
      mapRef?.current?.data?.setStyle(styleFeature);
      if (weatherData?.data?.features?.length) {
        mapRef?.current?.data?.addGeoJson(weatherData?.data);
        mapRef?.current?.data?.setStyle(styleFeature);
      }
      if (
        ((customerData?.data?.customers?.length >= 0 && isSuccess) ||
          (leadsData?.data?.leads?.length >= 0 && leadIsSuccess)) &&
        invalidCoordinates?.length === 0 &&
        isFilterApplied
      ) {
        let dataRaw = [];
        let both =
          isValidArray(customerData?.data?.customers) &&
          isValidArray(leadsData?.data?.leads) &&
          (mapFilters?.user?.value === "all" ||
            mapFilters?.user?.values === "all");
        clearAllMarkersAndCluster();
        if (
          both ||
          ((customerData?.data?.customers || leadsData?.data?.leads) &&
            (mapFilters?.user?.value === "all" ||
              mapFilters?.user?.values === "all"))
        ) {
          if (both) {
            dataRaw = [
              ...customerData?.data?.customers?.map((e) => {
                return {
                  ...e,
                  assignedTo: null,
                  leadId: null,
                };
              }),
              ...leadsData?.data?.leads?.map((e) => {
                return {
                  ...e,
                  employee: null,
                  nmdocid: null,
                  customerId: null,
                };
              }),
            ];
          } else if (isValidArray(customerData?.data?.customers)) {
            dataRaw = customerData?.data?.customers;
          } else if (isValidArray(leadsData?.data?.leads)) {
            dataRaw = leadsData?.data?.leads;
          }
        } else if (
          isValidArray(customerData?.data?.customers) &&
          customerValidateCons
        ) {
          dataRaw = customerData?.data?.customers;
        } else if (isValidArray(leadsData?.data?.leads) && leadsValidateCons) {
          dataRaw = leadsData?.data?.leads;
        }
        const markers = dataRaw?.map((data, index) => {
          const marker = new window.google.maps.Marker({
            position: { lat: data?.latitude, lng: data?.longitude },
            map: mapRef.current,
            icon: {
              url: data?.leadId ? leads : customer,
            },
            label:
              data?.leadId && data?.estimateAmount
                ? {
                    text: `$${data?.estimateAmount?.toLocaleString("en-US")}`,
                    color: "white",
                    className:
                      "marker-label rounded-pill  ",
                  }
                : "",
            key: data?.customerId,
          });
          marker.addListener("click", () => {
            setMarker(data);
          });
          return marker;
        });
        markersRef.current = markers;
        // markersRef.current = markersRef.current.concat(markers);
        if (isValidArray(markersRef.current.concat(markers))) {
          const clusterer = new MarkerClusterer({
            markers: markersRef.current,
            map: mapRef.current,
            options: {
              ignoreHidden: true,
            },
          });
          markerClustererRef.current = clusterer;
        }
      }
      // window.google.maps.event.trigger(mapRef.current, 'idle');
    },
    // eslint-disable-next-line
    [
      mapFilters,
      customerData,
      isSuccess,
      marker,
      weatherData,
      // drawerChange,
      invalidCoordinates,
      isFilterApplied,
      leadIsSuccess,
      markerClustererRef,
      isLeadDataLoading,
      leadsData,
      markersRef,
    ]
  );
  useEffect(() => {
    if (leadsData?.data?.leads?.length >= 0) {
      onLoadMap();
    }
    // eslint-disable-next-line
  }, [leadsData]);

  useEffect(() => {
    let data = setBodyForCustomerApi(mapFilters);
    let leadsData = {};
    if (mapFilters?.location) {
      leadsData = { ...leadsData, location: { ...mapFilters?.location } };
    }
    if (mapFilters["start date"] !== "" && mapFilters["end date"] !== "") {
      let startDate = formatDateCustomerFilter(mapFilters["start date"]);
      let endDate = formatDateCustomerFilter(mapFilters["end date"]);
      leadsData = {
        ...leadsData,
        startDate: startDate,
        endDate: endDate,
      };
    }
    if (mapFilters?.isFilterApplied) {
      if (
        notification?.values?.name === null ||
        notification?.values?.name === undefined
      ) {
        if (customerValidateCons) {
          getCustomers({
            url: "sage/customers",
            data: data,
          });
        }
        if (leadsValidateCons) {
          getLeads({
            url: "sage/leads",
            data: leadsData,
          });
        } else {
          setLeadData({});
        }
        setIsFilterApplied(true);
        setMarker(null);
      }
    } else {
      setIsFilterApplied(false);
    }
    // eslint-disable-next-line
  }, [mapFilters]);

  useEffect(() => {
    if (setIsApiCalling) {
      setIsApiCalling(
        !!(
          !isLoaded ||
          isLoadingWeatherData ||
          isCustomerDataLoading ||
          isLeadDataLoading
        )
      );
    }
    // eslint-disable-next-line
  }, [
    isLoaded,
    isLoadingWeatherData,
    isCustomerDataLoading,
    isLeadDataLoading,
  ]);
  //  filter functions
  const countForMapFilters = isHeader && mapFilters?.isFilterApplied;
  // .map(([key, values]) => getMapFilters(key, values))
  // ?.filter((e) => e !== undefined)?.length > 1;

  useEffect(() => {
    if (weatherDataSucess && weatherData) {
      onLoadMap();
    }
    // eslint-disable-next-line
  }, [weatherData, weatherDataSucess]);
  useEffect(() => {
    if (customerData?.data?.customers?.length <= 0 && isSuccess) {
      setIsCustomLoadding(false);
    }
    if (customerData?.data?.customers?.length >= 0 && isSuccess) {
      onLoadMap();
    }
    // eslint-disable-next-line
  }, [customerData, isSuccess]);
  const defaultBounds = {
    north: 85, // Upper latitude limit
    south: -85, // Lower latitude limit
    west: -180, // Western longitude limit
    east: 180, // Eastern longitude limit
  };
  return !isLoaded ? (
    <div
      className="d-flex justify-content-center align-items-center"
      style={containerStyle}
    >
      <PuffLoader
        color={"#00529D"}
        loading={true}
        size={176}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  ) : (
    <div className="map-container" style={{ position: "relative" }}>
      <GoogleMap
        options={{
          zoomControlOptions: {
            position: window.google.maps.ControlPosition.RIGHT_TOP,
          },
          restriction: {
            latLngBounds: defaultBounds,
            strictBounds: false, // If set to true, it will prevent dragging outside the bounds
          },
          minZoom: 3,
          maxZoom: 24,
          gestureHandling: "greedy",
          mapTypeControl: false,
          streetViewControl: false,
          fullscreenControl: false,
          styles: mapThemes.theme,
        }}
        ref={mapRef}
        zoom={zoomLevel}
        center={center}
        onLoad={onLoadMap}
        mapContainerStyle={mapStyle ?? containerStyle}
        onTilesLoaded={() => setCenter(null)}
        mapTypeId="roadmap"
        onClick={(event) => {
          if (event?.placeId) {
            event.stop();
          }
        }}
      >
        {(isLoadingWeatherData ||
          isCustomerDataLoading ||
          isCustomLoadding) && (
          <div
            className="position-fixed bottom-0 end-0 mt-5 d-flex justify-content-center align-items-center "
            style={{
              width: "100vw",
              height: "100vh",
              zIndex: "99999",
            }}
          >
            <PuffLoader
              color={
                ["Dark", "Night", "Aubergine"]?.includes(mapThemes?.name)
                  ? marker?.lat || marker?.latitude // when we have marker under dark theme we want loader of black color
                    ? "black"
                    : "white"
                  : "black"
              }
              loading={true}
              size={176}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        )}
        {isHeader && (
          <MapHeader
            isApiCalling={isApiCalling}
            weatherFilters={weatherFilters}
            setWeatherFilters={setWeatherFilters}
            weatherHistoryFilter={weatherHistoryFilter}
            setWeatherHistoryFilter={setWeatherHistoryFilter}
            showWeatherHistoryModal={showWeatherHistoryModal}
            setShowWeatherHistoryModal={setShowWeatherHistoryModal}
            handleWeatherHistoryModal={handleWeatherHistoryModal}
            getWeatherData={getWeatherData}
            isLoadingWeatherData={isLoadingWeatherData}
            sliderStyle={sliderStyle}
            hailSize={hailSize}
            mapThemes={mapThemes}
            setMapThemes={setMapThemes}
            setHailSize={setHailSize}
            handleHailSize={handleHailSize}
            toggleRightCanvas={toggleRightCanvas}
            setMarker={setMarker}
            countForMapFilters={countForMapFilters}
            isRight={isRight}
            handleSalesFilter={handleSalesFilter}
            showSalesModal={showSalesModal}
            clearAllMarkersAndCluster={clearAllMarkersAndCluster}
            setShowSalesModal={setShowSalesModal}
            resetFilter={resetFilter}
            setSalesFilter={setSalesFilter}
            salesFilter={salesFilter}
            mapRef={mapRef}
            searchedLocation={searchedLocation}
            setSearchedLocation={setSearchedLocation}
          />
        )}
        {hailMarker ? (
          <div>
            <InfoWindowF
              position={{ lat: hailMarker.lat, lng: hailMarker.lng }}
              options={{
                headerDisabled: true,
              }}
            >
              <Card className="card-body m-0 py-2 p-0 gap-2  weather-info-window d-flex">
                <div className="d-flex justify-content-between align-items-center">
                  <Tooltips msg={"Date"} direction={"left"}>
                    <h6 className=" mb-0">
                      <i
                        className="bi  bi-calendar-fill me-2"
                        style={{ color: "#00529D" }}
                      ></i>
                      &nbsp;
                      {dateFormat(hailMarker?.Date)}
                    </h6>
                  </Tooltips>
                  <span className="fs-5"> |</span>
                  <Tooltips msg={"HailSize"} direction={"left"}>
                    <h6 className=" mb-0">
                      <i className="bi bi-cloud-lightning-rain-fill text-primary me-2"></i>
                      {hailMarker?.hailSize}
                    </h6>
                  </Tooltips>
                </div>
              </Card>
            </InfoWindowF>
          </div>
        ) : null}
        {circleData && (
          <Circle
            center={circleData.center}
            radius={circleData.radius * 1609.34} // Convert miles to meters
            options={{
              fillColor: "rgba(255, 0, 0,0.4)",
              strokeColor: "rgba(255, 0, 0,0.5)",
              fillOpacity: 0.3,
              strokeOpacity: 0.8,
              strokeWeight: 1,
            }}
          />
        )}
        {polygonData?.length > 0 && (
          <Polygon
            options={{
              fillColor: "rgba(255, 0, 0,0.4)",
              strokeColor: "rgba(255, 0, 0,0.5)",
              strokeOpacity: 0.8,
              strokeWeight: 1,
              fillOpacity: 0.3,
            }}
            paths={polygonData}
          />
        )}

        {searchedLocation && (
          <MarkerF
            key={searchedLocation?.lat}
            position={{
              lat: searchedLocation?.lat,
              lng: searchedLocation?.lng,
            }}
            onClick={() => {
              if (clickableSearchLocation) {
                setMarker(searchedLocation);
              }
            }}
          >
            {marker?.lat === searchedLocation?.lat &&
              marker?.lan === searchedLocation?.lan &&
              marker?.isLocation && (
                <InfoWindowF
                  position={{
                    lat: searchedLocation?.lat,
                    lng: searchedLocation?.lng,
                  }}
                  options={{
                    headerDisabled: true,
                    maxWidth: "1200",
                  }}
                >
                  <InfoWindowHeader
                    salesFilter={salesFilter}
                    setSalesFilter={setSalesFilter}
                    marker={marker}
                    setMarker={setMarker}
                    weatherFilters={weatherFilters} // we want to change date when clicked on weather-date,
                    setWeatherFilters={setWeatherFilters}
                  />
                </InfoWindowF>
              )}
          </MarkerF>
        )}
        <SearchLocation
          setSearchedLocation={setSearchedLocation}
          mapRef={mapRef}
          inputRef={inputRef}
          isDisabled={
            !!(!isLoaded || isLoadingWeatherData || isCustomerDataLoading)
          }
          setMarker={setMarker}
        />
        <DrawingManagerComponent
          setIsFilterApplied={setIsFilterApplied}
          currentPolygon={currentPolygon}
          isHeader={isHeader}
          setCustomerFilter={setCustomerFilter}
          setCurrentPolygon={setCurrentPolygon}
          setCircleData={setCircleData}
          clearAllMarkersAndCluster={clearAllMarkersAndCluster}
          setPolygonData={setPolygonData}
          setInvalidCoordinates={setInvalidCoordinates}
          invalidCoordinates={invalidCoordinates}
          setMapFilters={setMapFilters}
          mapFilters={mapFilters}
          setSearchedLocation={setSearchedLocation}
          inputRef={inputRef}
          setMarker={setMarker}
        />
        {marker && !marker?.isLocation && (
          <MarkerComponent
            setMarker={setMarker}
            marker={marker}
            weatherFilters={weatherFilters}
            setWeatherFilters={setWeatherFilters}
            salesFilter={salesFilter}
            setSalesFilter={setSalesFilter}
          />
        )}
        {((customerData?.data?.customers?.length && customerValidateCons) ||
          (leadsData?.data?.leads?.length && leadsValidateCons)) &&
          (isFilterApplied || currentPolygon) &&
          !invalidCoordinates?.length && (
            <div className="map-custom-data">
              <div className="col">
                <div className="card-body card  ">
                  {customerData?.data?.customers?.length > 0 &&
                    customerValidateCons && (
                      <p className="mb-1">
                        <img
                          src={customer}
                          alt="Customer"
                          style={{ height: "24px", width: "24px" }}
                        />{" "}
                        <small>
                          {" "}
                          {customerData?.data?.total?.toLocaleString("en-US")}
                        </small>{" "}
                        - <small>Customers </small>
                      </p>
                    )}
                  {leadsData?.data?.leads?.length > 0 && leadsValidateCons && (
                    <p className="mb-1">
                      <img src={leads} alt="Leads" style={{ height: "24px" }} />{" "}
                      <small>
                        {leadsData?.data?.total?.toLocaleString("en-US")}
                      </small>{" "}
                      - <small>Leads</small>
                    </p>
                  )}
                </div>
              </div>
            </div>
          )}

        {isHeader && (
          <div className="map-custom-indicator">
            <div className="indicator ">
              <span className="pl-3">0</span>
              <span>1</span>
              <span>2</span>
              <span>3</span>
              <span>4</span>
              <span>5</span>
              <span>6</span>
            </div>
          </div>
        )}
        {/* </Tooltips> */}
      </GoogleMap>
      {isFilterApplied &&
        (customerData?.data?.customers?.length > 0 ||
          leadsData?.data?.leads?.length > 0) && (
          <div
            className="text-start m-1 position-fixed bottom-0 end-0"
            style={{ zIndex: "200" }}
          >
            <Tooltips msg={"Customers & Leads"} direction={"left"}>
              <div className="customizer-setting d-none d-md-block">
                <div
                  className="btn-info btn-rounded shadow-lg btn btn-icon btn-lg p-2"
                  onClick={() => toggleUserCanvas(showUserCanvas)}
                >
                  <i className="bi bi-person-fill fs-22"></i>
                </div>
              </div>
            </Tooltips>
          </div>
        )}

      {showUserCanvas && (
        <UserDrawer
          showUserCanvas={showUserCanvas}
          toggleUserCanvas={toggleUserCanvas}
          customerData={customerData}
          leadsData={leadsData}
          setMarker={setMarker}
          getCustomers={getCustomers}
          getLead={getLeads}
          onLoadMap={onLoadMap}
          setLeadData={setLeadData}
          isCustomerDataLoading={isCustomerDataLoading}
          isLeadLoading={isLeadDataLoading}
          mapFilters={mapFilters}
        />
      )}
    </div>
  );
};

export default React.memo(MapComponent);
