import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import ReactCrop, { convertToPixelCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import {
  MAX_IMAGE_SIZE,
  MAX_PROPERTY_IMAGE_SIZE,
  canvasPreview,
  centerAspectCrop,
  showSuccessMsg,
  useDebounceEffect,
} from "modules/helper";
import { useUploadProfileMutation } from "reduxStore/rtk/EndPoints/LoginEndPoint/userProfileSlice";

function ImageCroperModel({
  imageArry,
  onCancel,
  setFile,
  ratio = [{ value: undefined }],
  invalidImage,
  // aspect,
  modalShow,
  setModalShow,
  propertyImageCrop,
}) {
  const [allUploadedFile, SetAllUploadedFile] = useState(imageArry);
  const [btnLoad, setBtnLoad] = useState(false);
  const [imageUrl, SetImageUrl] = useState([]);
  const [imageIndex, setImageindex] = useState(0);
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const blobUrlRef = useRef("");
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [scale] = useState(1);
  const [rotate] = useState(0);
  const [aspect, setAspect] = useState(ratio[0].value);
  const [updateUser] = useUploadProfileMutation();
  async function getCroppedFile(
    image,
    previewCanvas,
    completedCrop,
    originalFileName,
    fileType,
  ) {
    if (!image || !previewCanvas || !completedCrop) {
      throw new Error("Crop canvas does not exist");
    }

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    const offscreen = new OffscreenCanvas(
      completedCrop.width * scaleX,
      completedCrop.height * scaleY,
    );
    const ctx = offscreen.getContext("2d");
    if (!ctx) {
      throw new Error("No 2d context");
    }

    ctx.drawImage(
      previewCanvas,
      0,
      0,
      previewCanvas.width,
      previewCanvas.height,
      0,
      0,
      offscreen.width,
      offscreen.height,
    );

    const blob = await offscreen.convertToBlob({ type: fileType });
    const croppedImage = new File([blob], originalFileName, {
      type: fileType,
      lastModified: Date.now(),
    });

    if (
      croppedImage.size > propertyImageCrop
        ? MAX_PROPERTY_IMAGE_SIZE * 1024 * 1024
        : MAX_IMAGE_SIZE * 1024 * 1024
    ) {
      // Resize the image if it exceeds 5 or 10 MB
      const maxWidth = image.width;
      const maxHeight = image.height;
      const resizedBlob = await resizeImageBlob(
        offscreen,
        fileType,
        maxHeight,
        maxWidth,
      );
      return createResult(resizedBlob, originalFileName);
    }

    // Return the original cropped image
    return createResult(blob, originalFileName);
  }

  // Helper function to resize the image blob
  async function resizeImageBlob(offscreen, fileType, maxHeights, maxWidths) {
    const maxWidth = maxWidths; // Set your desired max width
    const maxHeight = maxHeights; // Set your desired max height

    const scale = Math.min(
      (maxWidth / offscreen.width) * 2,
      (maxHeight / offscreen.height) * 2,
    );
    const resizedOffscreen = new OffscreenCanvas(
      offscreen.width * scale,
      offscreen.height * scale,
    );
    const resizedCtx = resizedOffscreen.getContext("2d");
    if (!resizedCtx) {
      throw new Error("No 2d context for resized canvas");
    }
    resizedCtx.drawImage(
      offscreen,
      0,
      0,
      resizedOffscreen.width,
      resizedOffscreen.height,
    );

    return resizedOffscreen.convertToBlob({ type: fileType });
  }

  // Helper function to create the result object
  function createResult(blob, originalFileName) {
    if (blobUrlRef.current) {
      URL.revokeObjectURL(blobUrlRef.current);
    }

    const croppedImage = new File([blob], originalFileName, {
      type: blob.type,
      lastModified: Date.now(),
    });

    blobUrlRef.current = URL.createObjectURL(croppedImage);

    return {
      file: croppedImage,
      preview: URL.createObjectURL(croppedImage),
    };
  }

  useEffect(() => {
    if (allUploadedFile) {
      Promise.all(
        allUploadedFile.map((file) => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function (e) {
              resolve(reader.result);
            };
          });
        }),
      )
        .then((results) => {
          // results is an array of all the reader results
          SetImageUrl([...results, ...imageUrl]);
        })
        .catch((error) => {
          // Handle any potential errors
          console.error("Error reading files:", error);
        });
      // setFile([]); // This line might need to be moved based on your requirements
    }
    // eslint-disable-next-line
  }, []);

  function handleToggleAspectClick() {
    if (imgRef.current) {
      const { width, height } = imgRef.current;
      const newCrop = centerAspectCrop(width, height, 16 / 9);
      setCrop(newCrop);
      // Updates the preview
      setCompletedCrop(convertToPixelCrop(newCrop, width, height));
    }
    // }
  }
  function onImageLoad(e) {
    const { width, height } = e.currentTarget;
    if (aspect) {
      setCrop(centerAspectCrop(width, height, aspect));
    } else {
      setCrop(centerAspectCrop(width, height, 16 / 9));
    }
  }

  // Example usage in onDownloadCropClick:
  async function onCrop() {
    setBtnLoad(true);
    const image = imgRef.current;
    const previewCanvas = previewCanvasRef.current;
    if (!image || !previewCanvas || !completedCrop) {
      handleToggleAspectClick();
      return;
    }
    try {
      await getCroppedFile(
        image,
        previewCanvas,
        completedCrop,
        allUploadedFile[imageIndex]?.name,
        allUploadedFile[imageIndex]?.type,
      ).then(async (blob) => {
        const updatedFile = [...allUploadedFile];
        updatedFile[imageIndex] = blob; // Update the specific index with the new blob
        setFile(updatedFile);
        //uploading image if crop is done (by clicking upload)
        try {
          const data = new FormData();
          data.append("profileImage", updatedFile[0].file);
          let res = await updateUser(data);
          if (res?.data?.type === "success") {
            showSuccessMsg(res?.data?.message);
          }
        } catch (error) {
          console.log(error.message);
        }

        if (imageUrl.length - 1 === imageIndex) {
          SetAllUploadedFile([]);
          // Clone the array to avoid mutating state directly
          // Clear the array when all images are processed
          onCancel(updatedFile);
          setCrop([{ value: undefined }]);
          setBtnLoad(false);
        } else {
          SetAllUploadedFile(updatedFile);
          // Clone the array to avoid mutating state directly

          setImageindex(imageIndex + 1);
          setBtnLoad(false);
        }
        setAspect(undefined);
      });
      // Now you can use the cropped file and preview URL as needed
    } catch (error) {
      setBtnLoad(false);
      console.error("Error cropping image:", error);
    }
  }
  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate,
        );
      }
    },
    100,
    [completedCrop, scale, rotate, imageIndex],
  );
  return (
    <React.Fragment>
      <Modal show={modalShow} centered className="fade zoomIn modal-lg">
        <Modal.Header>
          <Modal.Title as="h5" className="text-capitalize">
            Crop Image
          </Modal.Title>
          <button
            className="btn modal-closeBtn p-0"
            onClick={() => setModalShow(false)}
          >
            {/* <img src={closeIcon} className="" alt="Close" /> */}
            close
          </button>
        </Modal.Header>
        <Modal.Body className="custom-modalBody">
          <ReactCrop
            crop={crop}
            onChange={(_, percentCrop) => setCrop(percentCrop)}
            onComplete={(c) => setCompletedCrop(c)}
            aspect={aspect}
            minHeight={50}
            minWidth={50}
          >
            <img
              ref={imgRef}
              alt="Crop me"
              src={imageUrl[imageIndex]}
              style={{
                transform: `scale(${scale}) rotate(${rotate}deg)`,
                width: "100%",
                height: "auto",
              }}
              onLoad={onImageLoad}
            />
          </ReactCrop>
          {!!completedCrop && (
            <div>
              <canvas
                ref={previewCanvasRef}
                style={{
                  border: "1px solid black",
                  objectFit: "contain",
                  width: completedCrop.width,
                  height: completedCrop.height,
                  display: "none",
                }}
              />
            </div>
          )}
        </Modal.Body>

        <Modal.Footer className="custom-modalFooter align-items-center justify-content-between border-0 pt-0">
          <p className="text-danger">
            {invalidImage > 0 ? (
              <>
                {invalidImage} Will not be able to uploaded. *Image size more
                than
                {propertyImageCrop
                  ? MAX_PROPERTY_IMAGE_SIZE
                  : MAX_IMAGE_SIZE}{" "}
                MB
              </>
            ) : (
              <></>
            )}
          </p>
          {/* image length count  */}
          <div className="d-flex align-items-center gap-4 m-0">
            <button
              className="btn btn-md btn-yellow"
              disabled={btnLoad}
              onClick={(e) => {
                e.preventDefault();
                setBtnLoad(true);
                onCrop();
              }}
            >
              {btnLoad ? (
                <div className="d-flex justify-content-center align-items-center">
                  <div
                    className="spinner-border spinner-border-sm me-1"
                    role="status"
                  ></div>
                  <span>...</span>
                </div>
              ) : (
                "Upload"
              )}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default ImageCroperModel;
