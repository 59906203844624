export const userOptions = [
  { value: "all", label: "All" },
  { value: "customer", label: "Customer" },
  { value: "leads", label: "Leads" },
];
export const durationOptions = [
  { value: null, label: "Select Duration" },
  { value: "days-7", label: "7 Days" },
  { value: "days-30", label: "30 Days" },
  { value: "months-6", label: "6 Months" },
  { value: "months-12", label: "12 Months" },
  { value: "months-24", label: "24 Months" },
];
export const propertyOptions = [
  { value: null, label: "Select Property" },
  { value: "1", label: "Com-FW Ofc" },
  { value: "2", label: "Res-FW Ofc" },
  { value: "3", label: "Com-Dls Ofc" },
  { value: "4", label: "Res-Dls Ofc" },
];
export const typeOptions = [
  { value: null, label: "Select Type" },
  { value: "1", label: "Existing roof" },
  { value: "2", label: "New construction" },
  { value: "3", label: "Cost/Plus" },
  { value: "4", label: "Re-install (tile)" },
  { value: "5", label: "Solid repair" },
  { value: "6", label: "Cost/Plus repair" },
  { value: "7", label: "Emergency repair" },
];

export const structureOptions = [
  { value: null, label: "Select Structure" },
  { value: "10", label: "Residence" },
  { value: "11", label: "Apartment" },
  { value: "12", label: "Curch" },
  { value: "13", label: "Schools" },
  { value: "14", label: "Hospital" },
  { value: "15", label: "Bank" },
  { value: "16", label: "Shopping Center" },
  { value: "17", label: "CML Bdig-low rise  (Low Rise-2 story and lower)" },
  { value: "18", label: "CML Bdig-mid rise  (Mid Rise-3 story and above)" },
  { value: "19", label: "Multi-plex <4>" },
  { value: "20", label: "Warehouse" },
  { value: "80", label: "Other" },
];

export const redeckOptions = [
  { value: null, label: "Select Redeck" },
  { value: "0", label: "No" },
  { value: "1", label: "Yes" },
];

export const groupOptions = [
  { value: null, label: "Select Group" },
  { value: "02", label: "Metal Sys-Architectural" },
  { value: "04", label: "Metal Sys-Structural" },
  { value: "06", label: "Metal Sys-Coating" },
  { value: "08", label: "Metal Sys-Fiberglass" },
  { value: "11", label: "CML-Modified Bitumen" },
  { value: "12", label: "CML-Gravel Surface" },
  { value: "13", label: "CML-Slick Surface Mop" },
  { value: "14", label: "CML-Single Ply" },
  { value: "19", label: "CML-Other" },
  { value: "21", label: "3-Tab-20 yr" },
  { value: "22", label: "3-Tab-25 yr" },
  { value: "23", label: "3-Tab-30 yr" },
  { value: "29", label: "3-Tab-Other" },
  { value: "32", label: "Dimensional-30 yr" },
  { value: "33", label: "Dimensional-40 yr" },
  { value: "34", label: "Dimensional-35 yr" },
  { value: "35", label: "Dimensional-50 yr" },
  { value: "38", label: "Dimensional-lifetime" },
  { value: "39", label: "Dimensional-Other" },
  { value: "41", label: "Specialty" },
  { value: "43", label: "Specialty- Natural Slate" },
  { value: "49", label: "Specialty- Other" },
  { value: "52", label: "Tile-Clay" },
  { value: "55", label: "Tile-Concrete" },
  { value: "59", label: "Tile-Other" },
  { value: "62", label: "Steel-Gerard" },
  { value: "65", label: "Steel-Other" },
  { value: "71", label: "Cedar-raw" },
  { value: "72", label: 'Cedar-class "C"' },
  { value: "85", label: "Composition-Other" },
  { value: "92", label: "Insulation" },
  { value: "93", label: "Windows" },
];
