import React from "react";
import { Modal } from "react-bootstrap";
import WeatherHistoryInfoWindow from "../Maps/GoogleMap/InfowindowHeader/WeatherHistoryInfoWindow";
import { firstLetterUpperCase } from "modules/helper";

const SageWeatherDataModal = ({
  show,
  closeNearbySalesModal,
  weatherData,
  setWeatherData,
  location,
  address,
  weatherFilters,
  setWeatherFilters,
}) => {
  return (
    <Modal
      size="lg"
      show={show}
      onHide={() => closeNearbySalesModal()}
      backdrop={"static"}
      keyboard={false}
      id="staticBackdrop"
      className="fade zoomIn custom-modal"
      dialogClassName="modal-dialog-centered"
    >
      <Modal.Header
        className="modal-title fw-bold "
        id="staticBackdropLabel"
        closeButton
      >
        <h5 className="fw-bolder text-dark">Weather History</h5>
      </Modal.Header>
      <Modal.Body className="text-center pt-1">
        <p className="fw-bold text-dark text-start">
          <i
            className="bi bi-geo-alt-fill fs-5 me-1"
            style={{ color: "#00529D" }}
          ></i>
          {firstLetterUpperCase(address?.address)},
          {firstLetterUpperCase(address?.city)},&nbsp;
          {address?.state.toUpperCase()},&nbsp;
          {address?.zipcode}
        </p>
        {weatherData?.length > 0 && (
          <WeatherHistoryInfoWindow
            weatherData={weatherData}
            setWeatherData={setWeatherData}
            location={location}
            tableHeight={"350px"}
            weatherFilters={weatherFilters}
            setWeatherFilters={setWeatherFilters}
            closeNearbySalesModal={closeNearbySalesModal}
          />
        )}
      </Modal.Body>
    </Modal>
  );
};

export default SageWeatherDataModal;
