import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Route from "routes/Index";
import "./App.css";
import "./assets/scss/themes.scss";
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";
import { AuthProvider } from "components/context/UserContext";
import { useEffect, useState } from "react";
import {
  logOut,
  selectCurrentToken,
  selectLogout,
  selectServerError,
  serverError,
  setCredentials,
} from "reduxStore/rtk/EndPoints/LoginEndPoint/authSlice";
import {
  useLogoutMutation,
  useRefreshMutation,
} from "reduxStore/rtk/EndPoints/LoginEndPoint/LoginSlice";
import { getAuthToken, removeAuthToken, setAuthToken } from "modules/util";
import { PuffLoader } from "react-spinners";

function App() {
  const isLogout = useSelector(selectLogout);
  const token = useSelector(selectCurrentToken);
  const isServerError = useSelector(selectServerError);
  const locationState = useLocation();
  const [loading, setLoading] = useState(true);
  const [logoutMutation] = useLogoutMutation();
  const [refresh] = useRefreshMutation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      if (!!getAuthToken()) {
        try {
          const result = await refresh({
            refreshToken: getAuthToken() ?? "",
          }).unwrap();
          if (result.type === "success") {
            setAuthToken(result?.data?.refresh?.token);
            dispatch(setCredentials(result?.data?.access));
            // const userResult = await userProfile().unwrap()
            // if (userResult.type == "success") {
            //   dispatch(setUserProfile(userResult.data))
            // }
          }
          setLoading(false);
        } catch (error) {
          setLoading(false);
          if (error.status === "FETCH_ERROR") {
            dispatch(serverError(true));
            return;
          }
          dispatch(logOut());
        }
      } else {
        setLoading(false);
      }
    })();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isLogout && !getAuthToken()) {
      navigate("/");
    }
    // eslint-disable-next-line
  }, [isLogout, getAuthToken]);
  useEffect(() => {
    if (isServerError) {
      navigate("/server-error");
    }
    // eslint-disable-next-line
  }, [isServerError]);

  useEffect(() => {
    if (
      isLogout &&
      locationState.pathname !== "/login" &&
      !locationState.pathname.startsWith("/reset-password") &&
      locationState.pathname !== "/forgot-password" &&
      token
    ) {
      (async () => {
        try {
          setLoading(true);
          // eslint-disable-next-line
          const result = await logoutMutation({
            refreshToken: getAuthToken() ?? "",
            token: token,
          }).unwrap();
          removeAuthToken();
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
      })();
    }
    // eslint-disable-next-line
  }, [isLogout, locationState]);

  return (
    <>
      {!loading ? <Route /> : <PuffLoader />}

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        draggable
        pauseOnHover
        theme="light"
        style={{ minWidth: "350px" }}
      />
    </>
  );
}

export default App;
