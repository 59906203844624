export default function TextField({
  label = "",
  name = "",
  value = "",
  handleBlur = () => {},
  handleChange = () => {},
  placeholder = "",
  validationMsg = "",
  isDisabled = false,
  isReadOnly = false,
  icon = "",
  isIconRightSide = false,
}) {
  return (
    <div className="w-100 mt-3">
      <label htmlFor={name}>{label}</label>
      <div className="w-100 position-relative">
        {icon && !isIconRightSide && (
          <button className="btn position-absolute top-0 start-0 cursor-none">
            {icon}
          </button>
        )}
        <input
          id={name}
          name={name}
          type={"text"}
          value={value}
          onBlur={handleBlur}
          onChange={handleChange}
          className={`form-control rounded-0 bg-white fs-14 ${
            icon && isIconRightSide && "pe-5"
          }`}
          placeholder={placeholder}
          disabled={isDisabled}
          readOnly={isReadOnly}
          style={{ paddingLeft: icon && !isIconRightSide && "35px" }}
        />
        {!!icon && isIconRightSide && (
          <button className="btn position-absolute top-0 end-0 cursor-none">
            <i className={`${icon}`}></i>
          </button>
        )}
      </div>
      <div className="text-danger">{validationMsg}</div>
    </div>
  );
}
