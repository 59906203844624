import Navdata from "components/layout/LayoutMenuData";
import { isValidArray } from "modules/helper";
import { useState } from "react";
import { Collapse } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

const VerticalLayout = () => {
  const navigate = useNavigate();
  const navData = Navdata()?.menuItems ?? [];
  const userRole = localStorage.getItem("USER_ROLE");
  const [selectedMenuItem, setSelectedMenuItem] = useState("Dashboard");

  return (
    <>
      {isValidArray(navData) &&
        navData.map((item, key) => {
          return (
            <div key={key}>
              {item["isHeader"] ? (
                <div className="menu-title">
                  <span>{item?.label} </span>
                </div>
              ) : (
                item?.role.includes(userRole) &&
                (item?.subItems ? (
                  <div>
                    <div
                      data-bs-toggle="collapse"
                      className={`nav-link cursor-pointer ${selectedMenuItem === item?.label && `active`
                        }`}
                      onClick={(e) => {
                        item?.click(e);
                        setSelectedMenuItem(item?.label);
                        navigate(item?.link);
                      }}
                    >
                      <i className={item?.icon}></i>
                      <span
                        data-key="t-apps"
                        onClick={() => setSelectedMenuItem(item?.label)}
                      >
                        {item?.label}
                      </span>
                    </div>
                    <Collapse in={item?.stateVariables}>
                      <div className="">
                        {/* subItems  */}
                        {isValidArray(item?.subItems) &&
                          item?.subItems.map((subItem, key) => (
                            <div key={key} className="ps-2">
                              {!subItem?.isChildItem ? (
                                <div className="ps-0">
                                  <div
                                    className={`nav-link cursor-pointer ${selectedMenuItem === subItem?.label &&
                                      `active`
                                      }`}
                                    onClick={() => {
                                      setSelectedMenuItem(subItem?.label);
                                      navigate(subItem?.link);
                                    }}
                                  >
                                    <i className={subItem?.icon}></i>
                                    {subItem?.label}
                                  </div>
                                </div>
                              ) : (
                                <div>
                                  <div
                                    data-bs-toggle="collapse"
                                    className={`nav-link cursor-pointer ${selectedMenuItem === subItem?.label &&
                                      `active`
                                      }`}
                                    onClick={(e) => {
                                      subItem?.click(e);
                                      setSelectedMenuItem(subItem?.label);
                                      navigate("/");
                                    }}
                                  >
                                    <i className={subItem?.icon}></i>
                                    <span data-key="t-apps">
                                      {subItem?.label}
                                    </span>
                                  </div>
                                  <Collapse in={subItem?.stateVariables}>
                                    <div className="ps-2">
                                      {/* child subItms  */}
                                      {isValidArray(subItem?.childItems) &&
                                        subItem?.childItems?.map(
                                          (childItem, key) => (
                                            <div key={key} className="ps-2">
                                              {!childItem?.childItems ? (
                                                <div
                                                  className={`nav-link cursor-pointer ${selectedMenuItem ===
                                                    childItem?.label &&
                                                    `active`
                                                    }`}
                                                  onClick={() => {
                                                    setSelectedMenuItem(
                                                      childItem?.label
                                                    );
                                                    navigate(childItem?.link);
                                                  }}
                                                >
                                                  <i
                                                    className={childItem?.icon}
                                                  ></i>
                                                  <span data-key="t-apps">
                                                    {childItem?.label}
                                                  </span>
                                                </div>
                                              ) : (
                                                <>
                                                  <div
                                                    data-bs-toggle="collapse"
                                                    className={`nav-link cursor-pointer ${selectedMenuItem ===
                                                      childItem?.label &&
                                                      `active`
                                                      }`}
                                                    onClick={(e) => {
                                                      childItem?.click(e);
                                                      setSelectedMenuItem(
                                                        childItem?.label
                                                      );
                                                      navigate("/");
                                                    }}
                                                  >
                                                    <i
                                                      className={
                                                        childItem?.icon
                                                      }
                                                    ></i>
                                                    <span data-key="t-apps">
                                                      {childItem?.label}
                                                    </span>
                                                  </div>
                                                  <Collapse
                                                    in={
                                                      childItem?.stateVariables
                                                    }
                                                  >
                                                    <div className="ps-2">
                                                      {childItem?.childItems.map(
                                                        (subChildItem, key) => (
                                                          <div
                                                            key={key}
                                                            className={`nav-link cursor-pointer ${selectedMenuItem ===
                                                              subChildItem?.label &&
                                                              `active`
                                                              }`}
                                                            onClick={() => {
                                                              setSelectedMenuItem(
                                                                subChildItem?.label
                                                              );
                                                              navigate(
                                                                subChildItem?.link
                                                              );
                                                            }}
                                                          >
                                                            <i
                                                              className={
                                                                subChildItem?.icon
                                                              }
                                                            ></i>
                                                            <span data-key="t-apps">
                                                              {
                                                                subChildItem?.label
                                                              }
                                                            </span>
                                                          </div>
                                                        )
                                                      )}
                                                    </div>
                                                  </Collapse>
                                                </>
                                              )}
                                            </div>
                                          )
                                        )}
                                    </div>
                                  </Collapse>
                                </div>
                              )}
                            </div>
                          ))}
                      </div>
                    </Collapse>
                  </div>
                ) : (
                  <li className="nav-item">
                    <Link to={item.link ? item.link : "/"} className="nav-link menu-link">
                      <i className={item.icon}></i> <span>{item?.label}</span>
                      {item.badgeName ?
                        <span className={"badge badge-pill badge-soft-" + item.badgeColor} data-key="t-new">{item.badgeName}</span>
                        : null}
                    </Link>
                  </li>
                ))
              )}
            </div>
          );
        })}
    </>
  );
};

export default VerticalLayout;
