import TableContainer from "components/common/Tabledata/TableContainer";
import { Spinner } from "react-bootstrap";

export const ReactTable = ({
  data,
  isSuccess,
  columns,
  pageInfo,
  filter,
  setFilter,
  tableHeight = "auto",
}) => {
  return (
    <div>
      {isSuccess  ? (
        <Spinner size="sm" />
      ) : (
        <div>
          <TableContainer
            isPagination={true}
            columns={columns}
            data={data || []}
            customPageSize={data?.length}
            divClassName="table-card table-responsive"
            tableClass="table-hover table-nowrap align-middle mb-0 "
            isBordered={false}
            tableHeight={tableHeight}
            PaginationClass="align-items-center mt-4 gy-3"
            pageInfo={pageInfo}
            filter={filter}
              setFilter={setFilter}
          />
        </div>
      )}
    </div>
  );
};
