import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const PageTitle = ({ title = "React" }) => {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = `${title} | HailStorm`;
    // eslint-disable-next-line
  }, [navigate]);

  return <div></div>;
};

export default PageTitle;
