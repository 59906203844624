import { useFormik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";

import { Button, Form, Modal, Spinner } from "react-bootstrap";
import {
  useCreatUserMutation,
  useUpdateUserMutation,
} from "reduxStore/rtk/EndPoints/UserEndPoint/UserSlice";
import { showSuccessMsg } from "modules/helper";
import {
  emailFormat,
  passwordFormat,
  passwordFormatMsg,
} from "modules/validation";

/**This is a common modal used to
    -create user
    - edit user
  (based on edit props)
  ## edit prop- if we have edit user prop then-> edit user , else update user
 */
const UserModal = ({ modalTitle, isShow, handleClose, edit }) => {
  const [updateUser, { isLoading: updateLoading }] = useUpdateUserMutation(); // api to update user
  const [createUser, { isLoading }] = useCreatUserMutation(); // api to create new user
  const [showPassword, setShowPassword] = useState(false);

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };
  //setting initial value based on edit prop
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: (edit && edit._id) || "",
      name: (edit && edit.name) || "",
      memberImage: (edit && edit.memberImage) || "",
      email: (edit && edit.email) || "",
      role: (edit && edit.role) || "user",
      status: (edit && edit.isActive) || true,
      password: "",
      editMode: edit?._id ? true : false,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter User Name"),
      email: Yup.string()
        .email("Invalid Email")
        .matches(emailFormat, "Invalid Email")
        .required("Please Enter Email"),
      role: Yup.string().required("Please Enter Role"),
      password: Yup.string().when("editMode", {
        is: (value) => value === false,
        then: (schema) =>
          schema
            .required("Please Enter Password") // Defining validation schema for password field
            .min(8, "Password should be greater than or equal to 8 characters.")
            .max(50, "Password should be less than or equal to 50 characters.")
            .matches(passwordFormat, passwordFormatMsg),

        otherwise: (schema) => schema.nullable(true),
      }),
      status: Yup.string().required("Please choose Your status"),
    }),

    onSubmit: (values) => {
      let userData = {
        name: values?.name,
      };
      if (values.id !== "") {
        updateUser({ url: `/users/${edit._id}`, data: userData }).then(
          (res) => {
            if (res?.data?.type === "success") {
              showSuccessMsg(res?.data?.message);
              formik.resetForm();
              closeModal();
            }
          }
        );
      } else {
        userData = {
          ...userData,
          email: values?.email,
          password: values?.password,
        };
        createUser({ url: "/users", data: userData }).then((res) => {
          if (res?.data?.type === "success") {
            showSuccessMsg(res?.data?.message);
            formik.resetForm();
            closeModal();
          }
        });
      }
    },
  });

  const closeModal = () => {
    handleClose();
    formik.resetForm();
  };
  return (
    <React.Fragment>
      <Modal
        centered
        show={isShow}
        onHide={closeModal}
        style={{ display: "block" }}
        tabIndex={-1}
      >
        <div className="modal-content border-0">
          <Modal.Header className="p-4 pb-0">
            <Modal.Title as="h5">{modalTitle}</Modal.Title>
            <button
              type="button"
              className="btn-close"
              onClick={closeModal}
            ></button>
          </Modal.Header>
          <Modal.Body className="p-4">
            <Form autoComplete="off" onSubmit={formik.handleSubmit}>
              <div className="mb-3">
                <Form.Label htmlFor="name">
                  Name<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  id="name"
                  name="name"
                  // autoComplete={false}
                  placeholder="Enter User Name"
                  value={formik.values.name || ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={!!formik.errors.name && !!formik.touched.name}
                />
                {formik.errors.name && formik.touched.name ? (
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {formik.errors.name}
                  </Form.Control.Feedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Form.Label htmlFor="Email-input">
                  Email<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  id="Email-input"
                  name="email"
                  style={{
                    cursor: edit?._id && "not-allowed",
                  }}
                  className={`${edit?._id ? "bg-light text-muted" : ""}`}
                  // autoComplete={false}
                  placeholder="Enter Email"
                  disabled={edit?._id ? true : false}
                  value={formik.values.email || ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={!!formik.errors.email && !!formik.touched.email}
                />
                {formik.errors.email && formik.touched.email ? (
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.email}
                  </Form.Control.Feedback>
                ) : null}
              </div>
              {!edit?._id && (
                <div className="mb-3">
                  <Form.Label htmlFor="Email-input">
                    Password<span className="text-danger">*</span>
                  </Form.Label>
                  <div className="position-relative">
                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      id="password-input"
                      name="password"
                      placeholder="Enter Password"
                      value={formik.values.password || ""}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      isInvalid={
                        !!formik.errors.password && !!formik.touched.password
                      }
                      autoComplete="new-password"
                    />
                    <button
                      className={`btn position-absolute top-0 end-0 ${formik.errors.password && formik.touched.password && "me-4"}`}
                      type="button"
                      onClick={() => togglePassword()}
                      id="password-addon"
                    >
                      <i
                        className={`${showPassword ? `ri-eye-line` : `ri-eye-off-line`} `}
                      ></i>
                    </button>

                    {formik.errors.password && formik.touched.password ? (
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.password}
                      </Form.Control.Feedback>
                    ) : null}
                  </div>
                </div>
              )}
              <div className="hstack gap-2 justify-content-end">
                <Button
                  type="button"
                  className="btn btn-light"
                  onClick={closeModal}
                >
                  Close
                </Button>
                <Button
                  type="submit"
                  className="btn btn-success"
                  disabled={updateLoading || isLoading}
                >
                  {(updateLoading || isLoading) && <Spinner size="sm" />}
                  {edit?._id ? "Update" : "Add"}
                </Button>
              </div>
            </Form>
          </Modal.Body>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default UserModal;
