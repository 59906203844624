import { sage } from "reduxStore/Service";
import { authConfig } from "reduxStore/rtk/Query/Config";

export const sageApi = sage.injectEndpoints({
  tagTypes: ["sage"],
  endpoints: (builder) => ({
    getNearbySalesman: builder.mutation({
      query: (payload) => {
        let url = payload?.url;
        if (payload.export) {
          url += "?export=true";
        }
        return authConfig("POST", `${url}`, payload?.data);
      },
      transformResponse: (response) => {
        return response;
      },
      invalidatesTags: ["sage"],
    }),
    getCustomers: builder.mutation({
      query: (payload) => {
        let url = payload?.url;
        if (payload?.export) {
          url = `${payload?.url}?export=${payload?.export}`;
        }
        return authConfig("POST", url, payload?.data);
      },
      transformResponse: (response) => {
        return response;
      },
      invalidatesTags: ["sage"],
    }),
    getMaterialOptions: builder.query({
      query: (payload) => authConfig("GET", "sage/materials"),
      transformResponse: (response) => {
        return response;
      },
    }),
    getLeads: builder.mutation({
      query: (payload) => {
        let url = payload?.url;
        if (payload?.export) {
          url = `${payload?.url}?export=${payload?.export}`;
        }
        if (payload?.data) {
          return authConfig("POST", url, payload?.data);
        }
      },
      invalidatesTags: ["sage"],
    }),
    getNotificationFiles: builder.query({
      query: (payload) => {
        let url = payload?.url;
        if (payload?.name || payload?.isActive || payload?.sortBy?.createdAt || payload?.page) {
          url = `${payload?.url
            }${payload?.limit ? `?limit=${payload.limit}` : ""
            }${payload?.page ? `&page=${payload.page}` : ""
            }${payload?.sortBy?.createdAt ? `&sortBy=createdAt:${payload.sortBy.createdAt}` : ""}`;
        }
        return authConfig("GET", url);
      }
    }),
    downloadNotificationFiles: builder.mutation({
      query: (payload) => authConfig("POST", payload?.url, payload.data)
    }),
    syncSalesPerson: builder.mutation({
      query: (payload) => authConfig("POST", payload?.url, payload.data),
      invalidatesTags:["user"]
    })
  }),
});

export const {
  useGetNearbySalesmanMutation,
  useGetCustomersMutation,
  useGetMaterialOptionsQuery,
  useGetLeadsMutation,
  useLazyGetNotificationFilesQuery,
  useGetNotificationFilesQuery,
  useDownloadNotificationFilesMutation,
  useSyncSalesPersonMutation
} = sageApi;

