import clientLogo from "assets/images/clientLogo.png";
import PasswordField from "components/common/fields/PasswordField";
import ProcessButton from "components/common/fields/ProcessButton";

import { ErrorMessage, Form, Formik } from "formik";
import { showErrorMsg, showSuccessMsg } from "modules/helper";
import { resetPasswordFormValidation } from "modules/validation";
import { Card, Container } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { useResetPasswordMutation } from "reduxStore/rtk/EndPoints/LoginEndPoint/LoginSlice";

const ResetPassword = () => {
  const [searchParams] = useSearchParams();
  const [resetPassword, { isLoading }] = useResetPasswordMutation();
  const initialValues = {
    password: "",
    confimPassword: "",
  };

  const handleSubmit = async (values) => {
    if (searchParams.get('token')) {
      let res = await resetPassword({ token: searchParams.get('token'), password: values?.password });
      if (res?.data?.type === "success") {
        showSuccessMsg(res?.data?.message);
      }
    } else {
      showErrorMsg("Token Missing! Try Again");
    }
  };

  return (
    <div className="account-pages">
      <Container>
        <div className="d-flex flex-column justify-content-center">
        <div className="text-center my-5">
            <span className="logo-lg ">
              <img src={clientLogo} alt="" height="95" />
            </span>
          </div>
          <div className="my-5 d-flex justify-content-center align-items-center">
            <Card className="w-50 my-auto rounded-0">
              <Card.Body className="p-lg-5 p-4">
                <div className="text-center">
                  <h5 className="mb-0">Reset Password</h5>
                </div>

                <Formik
                  initialValues={initialValues}
                  validationSchema={resetPasswordFormValidation()}
                  onSubmit={handleSubmit}
                >
                  {({
                    errors,
                    touched,
                    values,
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    setFieldTouched,
                  }) => (
                    <Form
                      id="form1"
                      name="form1"
                      action="#"
                      className="mt-4"
                      onSubmit={handleSubmit}
                      onKeyDown={(e) => {
                        e.key === "Enter" && e.preventDefault();
                      }}
                    >
                      <PasswordField
                        label="Password"
                        name="password"
                        value={values?.password}
                        placeholder="Enter Password"
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        validationMsg={<ErrorMessage name="password" />}
                      />
                      <PasswordField
                        label="Confirm Password"
                        name="confirmPassword"
                        value={values?.confirmPassword}
                        placeholder="Confirm Password"
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        validationMsg={<ErrorMessage name="confirmPassword" />}
                      />

                      <div className="mt-2">
                        <ProcessButton
                          label={"Reset Password"}
                          type={"submit"}
                          isLoading={isLoading}
                        />
                      </div>
                    </Form>
                  )}
                </Formik>
              </Card.Body>
            </Card>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ResetPassword;
